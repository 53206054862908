import React, { useEffect, useState } from "react";
import { Table, Typography, Pagination, Button, Modal, message, Spin } from "antd";
import { useNavigate } from "react-router";
import { Copy } from "react-feather";
import { MdDeleteForever } from "react-icons/md";
import "./Property.css";
import PropertiesIcon from "../../assets/properties.svg";
import DashboardIcon from "../../assets/dashboard.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertiesList,
  setCurrentPage,
  setPageSize,
  setPropertyId,
  getFilteredProperty,
  setPropertyInitialState,
  setTransactionCnt,
  deleteProperty,
  setPropertyDetails,
} from "../../feature/slice/PropertyDetailsSlice";
import {
  setCurrentPage as _setTransactionsCurrentPage,
  setPageSize as _setTransactionsPageSize,
} from "../../feature/slice/TransactionDetailsSlice";
import { setFilters } from "../../feature/slice/FiltersSlice";
import { ReloadOutlined } from "@ant-design/icons";
import { bulkUploadProperties } from "../../feature/services/PropertyDetailsAPI";
import { CommonFilter } from '../../components/filterCard/FilterCard'
import dayjs from "dayjs";
import debounce from 'lodash.debounce';
const Property = (props) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { loading, propertyDetailsData, currentPage, pageSize, propertiesCnt } =
    useSelector((store) => store.propertyDetails);
  const { localitiesData, citiesData } = useSelector(
    (store) => store.filters
  );
  const [messageApi, contextHolder] = message.useMessage();

  const [filterProperty, setFilterProperty] = useState({
    propertyName: '',
    propertyNameFilter: 'contains',
    locality: [],
    city: [],
    noTransaction: null,
    noTransactionFilter: 'equals',
    startDate: null,
    endDate: null,
    viewMode: "commercial"
  })

  // const handleRowClick = (record) => {
  //   handleResetPropertySearch();
  //   navigateTo(`/propertyDetails/${record?._id}`);
  //   dispatch(setPropertyId(record?._id));
  // };

  useEffect(() => {
    fetchProperties(1, 10);
    dispatch(setPropertyId(null));
    dispatch(setPropertyDetails({}));
    dispatch(setPropertyId(null));
  }, []);

  const fetchProperties = async (page, size, updatedFilterProperty = filterProperty) => {
    try {
      dispatch(setPropertyId(null));
      dispatch(setCurrentPage(page));
      dispatch(setPageSize(size));
      if (
        updatedFilterProperty?.propertyName ||
        updatedFilterProperty?.noTransaction ||
        updatedFilterProperty?.locality?.length > 0 ||
        updatedFilterProperty?.city?.length > 0 ||
        updatedFilterProperty?.startDate ||
        updatedFilterProperty?.endDate ||
        updatedFilterProperty?.viewMode
      ) {
        const body = {
          propertyName: updatedFilterProperty?.propertyName,
          propertyNameFilter: updatedFilterProperty?.propertyNameFilter,
          locality: updatedFilterProperty?.locality,
          city: updatedFilterProperty?.city,
          noTransaction: +updatedFilterProperty?.noTransaction || null,
          noTransactionFilter: updatedFilterProperty?.noTransactionFilter,
          startDate: updatedFilterProperty?.startDate,
          endDate: updatedFilterProperty?.endDate,
          viewMode: updatedFilterProperty?.viewMode,
          currentPage: page,
          pageSize: size,
        };
        dispatch(getFilteredProperty(body));
      } else {
        const propertyType =
          filterProperty.viewMode === "residential" ? "Residential" : "Commercial";
        const body = {
          pageSize: size,
          page,
          viewMode: propertyType,
        };
        dispatch(getPropertiesList(body));
      }
    } catch (error) {
      console.error("Failed to fetch properties:", error);
      messageApi.error("Failed to fetch properties");
    }
  };


  const handleDelete = (propertyId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this property?",
      onOk: async () => {
        try {
          const response = await dispatch(deleteProperty(propertyId)).unwrap();
          messageApi.success(response.message || "Property deleted successfully");

          setTimeout(() => {
            dispatch(setCurrentPage(1));
            fetchProperties(currentPage, pageSize);
          }, 3000);
        } catch (error) {
          messageApi.error(error.message || "Failed to delete property");
        }
      },
    });
  };

  const handleRowClick = (record) => {
    handleResetPropertySearch();
    navigateTo(`/propertyDetails/${record?._id}`);
    dispatch(setPropertyId(record?._id));
  };

  const handleCopyId = (text) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        message.success("ID copied to clipboard");
      }).catch(err => {
        message.error("Failed to copy ID");
        console.error("Error copying to clipboard:", err);
      });
    } else {
      message.error("Clipboard API not supported");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      width: "3%",
      editable: false,
      render: (text) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            height: "100%",
            lineHeight: "32px",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleCopyId(text);
          }}
        >
          <Copy size={20} className="text-secondary" />
        </div>
      ),
    },
    {
      title: "Property Name",
      dataIndex: "propertyName",
      width: "30%",
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.propertyName}
          filterType={filterProperty.propertyNameFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, propertyName: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, propertyNameFilter: value })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, propertyName: null, propertyNameFilter: 'contains' }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the property name here"
        />
      ),
      filterSearch: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Locality",
      dataIndex: "locality",
      width: "15%",
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.locality || []}  // Initialize with an empty array if no filter value
          onFilterChange={(value) => {
            setFilterProperty({ ...filterProperty, locality: value });  // Update filter value
          }}
          onApply={() => {
            fetchProperties(1, 10);  // Fetch new data based on filter
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, locality: [] }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          inputType="select"  // Set input type to 'select'
          mode="multiple"  // Allow multiple selections
          placeholder="Select a locality"
          selectOptions={localitiesData.map(locality => ({  // Map localities to options
            value: locality.title,
            label: locality.title,
          }))}
        />
      ),
      onFilter: (value, record) => record.locality.includes(value),  // Filter function
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}  // Row click handler
        >
          {text}
        </div>
      ),
    },
    {
      title: "No of Transactions",
      dataIndex: "numberOfTransaction",
      width: "10%",
      editable: true,
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterProperty.noTransaction}
          filterType={filterProperty.noTransactionFilter}
          onFilterChange={(value) => setFilterProperty({ ...filterProperty, noTransaction: value })}
          onFilterTypeChange={(value) => setFilterProperty({ ...filterProperty, noTransactionFilter: value })}
          onApply={() => {
            fetchProperties(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, noTransaction: null, noTransactionFilter: 'equals' }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
          placeholder="Enter the number of transactions here"
        />
      ),
      filterSearch: true,
    },
    {
      title: "City",
      dataIndex: "city",
      width: "15%",
      editable: true,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          filterValue={filterProperty.city || []}
          onFilterChange={(value) => {
            const updatedFilterProperty = { ...filterProperty, city: value };
            setFilterProperty(updatedFilterProperty);
          }}
          onApply={() => {
            fetchProperties(1, 10);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, city: null }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
          inputType="select"
          mode="multiple"
          placeholder="Select a city"
          selectOptions={citiesData.map(city => ({
            value: city.title,
            label: city.title,
          }))}
        />
      ),
      render: (text, record) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleRowClick(record)}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      width: "15%",
      render: (createdAt) =>
        createdAt ? dayjs(createdAt).format("DD-MM-YYYY") : null,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="dateRange"
          startDate={filterProperty.startDate}
          endDate={filterProperty.endDate}
          onStartDateChange={(date) => setFilterProperty({ ...filterProperty, startDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onEndDateChange={(date) => setFilterProperty({ ...filterProperty, endDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onApply={() => {
            fetchProperties(1, 10, filterProperty);
            confirm();
          }}
          onReset={() => {
            const updatedFilterProperty = { ...filterProperty, startDate: null, endDate: null }
            setFilterProperty(updatedFilterProperty)
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            fetchProperties(1, 10, updatedFilterProperty);
            clearFilters();
            confirm();
          }}
        />
      ),
    },
    {
      title: "Operation",
      dataIndex: "_id",
      width: "25%",
      render: (_, record) => (
        <Typography.Link
          onClick={() => {
            handleResetPropertySearch();
            dispatch(setPropertyId(record?._id));
            dispatch(setTransactionCnt(record?.numberOfTransaction));
            dispatch(_setTransactionsCurrentPage(1));
            dispatch(_setTransactionsPageSize(10));
            navigateTo(`/transactionListDetails/${record?._id}`);
          }}
        >
          Transaction
        </Typography.Link>
      ),
    },
    {
      title: "Completion Ratio",
      dataIndex: "percentage",
      width: "5%",
      editable: true,
      render: (text) => (
        <div
          style={{
            height: "100%",
            lineHeight: "32px",
            cursor: "pointer",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "delete",
      width: "3%",
      editable: true,
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(record._id);
          }}
        >
          <MdDeleteForever size={20} className="text-secondary" />
        </div>
      ),
    },
  ];

  const handleResetPropertySearch = () => {
    dispatch(
      setFilters({
        name: "localities",
        value: [],
      })
    );
    const buildingNameBody = {
      value: null,
      name: "buildingName",
    };
    dispatch(setFilters(buildingNameBody));

    const documentNoBody = {
      value: null,
      name: "documentNo",
    };
    dispatch(setFilters(documentNoBody));
  };

  const fetchPropertiesDebounced = debounce((page, size, updatedFilterProperty) => {
    fetchProperties(page, size, updatedFilterProperty);
  }, 500);


  const toggleViewMode = () => {
    const newMode = filterProperty.viewMode === "residential" ? "commercial" : "residential";
    const updatedFilterProperty = { ...filterProperty, viewMode: newMode };
    setFilterProperty(updatedFilterProperty);
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    fetchPropertiesDebounced(1, 10, updatedFilterProperty);
  };

  const handlePaginationChange = (page, size) => {
    fetchProperties(page, size);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const response = await bulkUploadProperties(file);
        const successMessage = response;
        successBulkUpload(successMessage);
        event.target.value = null;
        setTimeout(() => {
          dispatch(setCurrentPage(1));
          fetchProperties(1, pageSize);
          event.target.value = null;
        }, 3000);
      } catch (error) {
        console.error("Failed to upload file:", error);
        const errorMessage = error.message;
        failBulkUpload(errorMessage);
      }
    }
  };
  const successBulkUpload = (messageContent) => {
    messageApi.open({
      type: "success",
      content: messageContent,
    });
  };

  const failBulkUpload = (messageContent) => {
    messageApi.open({
      type: "error",
      content: messageContent,
    });
  };

  return (
    <React.Fragment>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          {contextHolder}
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "20px",
              }}
            >
              <div className="m-4 align-center property">
                {/* {!props.filterCardVisible && (
                  <Button
                    onClick={props.toggleFilterCard}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "18px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <img
                        style={{ width: "24px" }}
                        src={FilterIcon}
                        alt="Filter"
                      />
                      Filter
                    </div>
                  </Button>
                )} */}
                <h1>Property List</h1>
              </div>
              <div className="list-options m-4 flex">
                <div className="flipswitch">
                  <input
                    type="checkbox"
                    id="fs"
                    className="flipswitch-cb"
                    name="flipswitch"
                    checked={filterProperty.viewMode === "residential"} // Set checkbox state based on viewMode
                    onChange={toggleViewMode}
                  />
                  <label
                    htmlFor="fs"
                    className="flipswitch-label"
                  >
                    <div className="flipswitch-inner">

                    </div>
                    <div className="flipswitch-switch">
                      <div
                        className={`flipswitch-image commercial-image ${filterProperty.viewMode === "commercial" ? "active" : ""}`}
                        style={{ backgroundImage: `url(${DashboardIcon})` }}
                      />
                      <div
                        className={`flipswitch-image residential-image ${filterProperty.viewMode === "residential" ? "active" : ""}`}
                        style={{ backgroundImage: `url(${PropertiesIcon})` }}
                      />

                    </div>
                  </label>
                </div>

                <a href="/Building_Sample_Data.xlsx" download>
                  <Button className="mr-4 ml-4" color="primary">
                    Download Sample
                  </Button>
                </a>
                <Button
                  className="mr-4 ml-4"
                  onClick={() => document.getElementById("bulkUploadInput").click()}
                >
                  Bulk Upload
                </Button>
                <input
                  type="file"
                  id="bulkUploadInput"
                  accept=".xlsx, .xls"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                <Button
                  className="mr-4 ml-4"
                  onClick={() => {
                    handleResetPropertySearch();
                    navigateTo(`/propertyDetails`);
                    dispatch(setPropertyId(null));
                    dispatch(setPropertyInitialState());
                  }}
                >
                  Add Property
                </Button>
                <ReloadOutlined
                  style={{ cursor: "pointer" }}
                  title="Reload Property List"
                  onClick={() => {
                    const body = {
                      pageSize: pageSize,
                      page: currentPage,
                      viewMode: filterProperty.viewMode === "residential" ? "Residential" : "Commercial",
                    };
                    dispatch(setCurrentPage(1));
                    dispatch(setPageSize(10));
                    dispatch(getPropertiesList(body));
                    setFilterProperty({
                      propertyName: '',
                      propertyNameFilter: 'contains',
                      locality: [],
                      city: [],
                      noTransaction: null,
                      noTransactionFilter: 'equals'
                    })
                  }}
                />
              </div>
            </div>
            <Table
              className="w-100 m-4 mt-0"
              bordered
              dataSource={propertyDetailsData}
              columns={columns}
              size="small"
              loading={loading}
              pagination={false}
              rowKey={"_id"}
            />
            {propertyDetailsData?.length > 0 && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                showSizeChanger
                onShowSizeChange={(current, size) => handlePaginationChange(current, size)}
                onChange={(currPage, pageSize) => handlePaginationChange(currPage, pageSize)}
                total={propertiesCnt}
              />
            )}
          </div>
        </>
      )}
    </React.Fragment>
  );
};
export default Property;
