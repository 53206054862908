import { Tree, Spin } from "antd";
import React from "react";

import "./FiltersTree.css";
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../feature/slice/FiltersSlice";

const FiltersTree = (props) => {
  const dispatch = useDispatch();
  const { localitiesLoading, citiesLoading } = useSelector(
    (store) => store.filters
  );

  const onCheck = (checkedKeys, info) => {
    dispatch(
      setFilters({
        name: props?.name,
        value: checkedKeys,
        obj: props?.obj,
      })
    );
  };
  return (
    <>
      {!localitiesLoading && !citiesLoading ? (
        <Tree
          checkable
          onCheck={onCheck}
          treeData={props?.data}
          style={{ maxHeight: "200px", overflowY: "scroll" }}
          selectedKeys={props?.value}
          checkedKeys={props?.value}
        />
      ) : (
        <Spin spinning={localitiesLoading || citiesLoading} size="medium" />
      )}
    </>
  );
};

export default FiltersTree;
