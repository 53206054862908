import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Col,
  Row,
  Divider,
  Select,
  Button,
  Spin,
  Upload,
  message,
  Collapse,
  Image,
  Typography,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
// import VerifySwitch from "../../components/verifySwitch/VerifySwitch";
import { v4 as uuidv4 } from "uuid"; // Import v4 function as uuidv4
import { isPAN, isGST } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import FormElement from "../../components/formElement/FormElement";
import Header from "../../components/header/Header";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  getCompanyDetailsData,
  saveCompanyInfo,
  setCompanyId,
  setCompanyInfo,
  setCompanyInfoEditable,
  setCompanyInitialState,
  setLoading,
  updateCompanyInfo,
  getAllDistinctCountryMaster,
  getAllDistinctStateByCountry,
  getAllDistinctCityByState,
  getAllDistinctDistrictByCity,
  getAllDistinctLocalityByDistrict,
  validateCIN,
} from "../../feature/slice/CompanyDetailsSlice";

const { Text } = Typography;

const companyTypeOptions = [
  {
    label: "Private Limited Company",
    key: "Private Limited Company",
    value: "CIN",
  },
  {
    label: "Limited Liability Partnership",
    key: "Limited Liability Partnership",
    value: "LLPIN",
  },
  {
    label: "One Person Company",
    key: "One Person Company",
    value: "CIN",
  },
  {
    label: "Not for profit license company",
    key: "Not for profit license company",
    value: "CIN",
  },
  {
    label: "Public Limited Company",
    key: "Public Limited Company",
    value: "CIN",
  },
  {
    label: "Subsidiary of a foreign company",
    key: "Subsidiary of a foreign company",
    value: "CIN",
  },
  {
    label: "Foreign Company",
    key: "Foreign Company",
    value: "FCRN",
  },
  {
    label: "General Association Private",
    key: "General Association Private",
    value: "CIN",
  },
  {
    label: "General Association Public",
    key: "General Association Public",
    value: "CIN",
  },
  {
    label: "Companies owned by government of india",
    key: "Companies owned by government of india",
    value: "CIN",
  },
  {
    label: "Companies owned by state government",
    key: "Companies owned by state government",
    value: "CIN",
  },
  {
    label: "Private limited companies with unlimited liability",
    key: "Private limited companies with unlimited liability",
    value: "CIN",
  },
  {
    label: "Financial lease company",
    key: "Financial lease company",
    value: "CIN",
  },
  {
    label: "Proprietorship firm",
    key: "Proprietorship firm",
    value: "-",
  },
  {
    label: "Partnership firms",
    key: "Partnership firms",
    value: "-",
  },
];

const getCompanyTypeListingEle = () => {
  let data = [...companyTypeOptions];
  return (
    <>
      {data?.map((item) => (
        <Select.Option
        
          value={item?.label}
          key={item?.key}
          companyType={item?.value}
        >
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getCompanyCategoryListingEle = () => {
  let data = [
    {
      label: "Company limited by guarantee",
      key: "Company limited by guarantee",
    },
    {
      label: "Company limited by shares",
      key: "Company limited by shares",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getCompanySubCategoryListingEle = () => {
  let data = [
    {
      label: "Subsidiary of company incorporated outside India",
      key: "Subsidiary of company incorporated outside India",
    },
    {
      label: "Guarantee and association Company",
      key: "Guarantee and association Company",
    },
    {
      label: "State government company",
      key: "State government company",
    },
    {
      label: "Union government company",
      key: "Union government company",
    },
    {
      label: "Non-government company",
      key: "Non-government company",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getCompanyOriginListingEle = () => {
  let data = [
    {
      label: "Foreign",
      key: "Foreign",
    },
    {
      label: "Indian",
      key: "Indian",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getClassofCompanyListingEle = () => {
  let data = [
    {
      label: "Public",
      key: "Public",
    },
    {
      label: "One Person Company",
      key: "One Person Company",
    },
    {
      label: "Private",
      key: "Private",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getLLPStatusListingEle = () => {
  let data = [
    {
      label: "Dormant under section 455",
      key: "Dormant under section 455",
    },
    {
      label: "Active",
      key: "Active",
    },
    {
      label: "Amalgamated",
      key: "Amalgamated",
    },
    {
      label: "Inactive for e-filing",
      key: "Inactive for e-filing",
    },
    {
      label: "Strike Off-AwaitingPublication",
      key: "Strike Off-AwaitingPublication",
    },
    {
      label: "Under Liquidation",
      key: "Under Liquidation",
    },
    {
      label: "Dissolved under section 59(8)",
      key: "Dissolved under section 59(8)",
    },
    {
      label: "Strike Off",
      key: "Strike Off",
    },
    {
      label: "Under process of striking off",
      key: "Under process of striking off",
    },
    {
      label: "Converted to LLP",
      key: "Converted to LLP",
    },
    {
      label: "Inactive",
      key: "Inactive",
    },
    {
      label: "Dissolved (Liquidated)",
      key: "Dissolved (Liquidated)",
    },
    {
      label: "Converted and Dissolved",
      key: "Converted and Dissolved",
    },
    {
      label: "Converted to CMP and Dissolved",
      key: "Converted to CMP and Dissolved",
    },
    {
      label: "Under CIRP",
      key: "Under CIRP",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getRDNameListingEle = () => {
  let data = [
    {
      label: "RD Noida",
      key: "RD Noida",
    },
    {
      label: "RD Mumbai",
      key: "RD Mumbai",
    },
    {
      label: "RD Hyderabad",
      key: "RD Hyderabad",
    },
    {
      label: "RD Kolkata",
      key: "RD Kolkata",
    },
    {
      label: "RD Chennai",
      key: "RD Chennai",
    },
    {
      label: "RD Ahmedabad",
      key: "RD Ahmedabad",
    },
    {
      label: "RD Guwahati",
      key: "RD Guwahati",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getRDRegionListingEle = () => {
  let data = [
    {
      label: "Northern Region",
      key: "Northern Region",
    },
    {
      label: "Western Region",
      key: "Western Region",
    },
    {
      label: "Eastern Region",
      key: "Eastern Region",
    },
    {
      label: "South East Region",
      key: "South East Region",
    },
    {
      label: "North Eastern Region",
      key: "North Eastern Region",
    },
    {
      label: "North Western Region",
      key: "North Western Region",
    },
    {
      label: "Southern Region",
      key: "Southern Region",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getOfficeTypeListingEle = () => {
  let data = [
    {
      label: "Branch office",
      key: "Branch office",
    },
    {
      label: "Liaison office",
      key: "Liaison office",
    },
    {
      label: "Project office",
      key: "Project office",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getCompanyListedFlagListingEle = () => {
  let data = [
    {
      label: "Yes",
      key: "Yes",
    },
    {
      label: "No",
      key: "No",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

function CompanyDetailsCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [logo, setLogo] = useState([]);
  const [deletedLogo, setDeletedLogo] = useState([]);
  const [deletedFileList, setDeletedFileList] = useState([]);
  const [showCreateDirectorBtn, setShowCreateDirectorBtn] = useState(false);
  const [showAddressTypeValidation, setShowAddressTypeValidation] =
    useState(false);
  const [savedAddressList, setSavedAddressList] = useState(null);
  const [selectedAddressType, setSelectedAddressType] = useState(null);
  const [savedAddressCnt, setSavedAddressCnt] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [showCompanyAddressInfoKey, setShowCompanyAddressInfoKey] = useState(0);
  const [cinForCompanyType, setCinForCompanyType] = useState(null);
  const [isCompanyAddressInfoEditable, setIsCompanyAddressInfoEditable] =
    useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    isCompanyInfoEditable,
    loading,
    companyDetails,
    companyId,
    countryMaster,
    stateMaster,
    cityMaster,
    districtMaster,
    localityMaster,
    navigatedFrom,
  } = useSelector((store) => store.companyDetails);
  const { connectId } = useSelector((store) => store.connectDetails);
  const { transactionId } = useSelector((store) => store.transactionDetails);

  const getCountryListingEle = () => {
    if (!countryMaster) return [];
    let data = [...countryMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getStateListingEle = () => {
    if (!stateMaster) return [];
    let data = [...stateMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getCityListingEle = () => {
    if (!cityMaster) return [];
    let data = [...cityMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getDistrictListingEle = () => {
    if (!districtMaster) return [];
    let data = [...districtMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getLocalityListingEle = () => {
    if (!localityMaster) return [];
    let data = [...localityMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };
  const isAddressTypePresent = (val) => {
    return savedAddressList.some((address) => address.label === val);
  };
  const companyAddressInfoFormElementsCol = [
    {
      type: "input",
      label: "Street Address",
      name: "streetAddress",
      placeholder: "Street Address",
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value:
        companyDetails?.addresses?.[showCompanyAddressInfoKey]?.streetAddress,
    },
    {
      type: "input",
      label: "Street Address 2",
      name: "streetAddress2",
      placeholder: "Street Address 2",
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value:
        companyDetails?.addresses?.[showCompanyAddressInfoKey]?.streetAddress2,
    },
    {},
    {
      type: "input",
      label: "Street Address 3",
      name: "streetAddress3",
      placeholder: "Street Address 3",
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value:
        companyDetails?.addresses?.[showCompanyAddressInfoKey]?.streetAddress3,
    },
    {
      type: "input",
      label: "Street Address 4",
      name: "streetAddress4",
      placeholder: "Street Address 4",
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value:
        companyDetails?.addresses?.[showCompanyAddressInfoKey]?.streetAddress4,
    },
    {
      type: "dropdown",
      label: "Country",
      name: "country",
      placeholder: "Country",
      children: getCountryListingEle(),
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value: companyDetails?.addresses?.[showCompanyAddressInfoKey]?.country,
    },
    {
      type: "dropdown",
      label: "State",
      name: "state",
      placeholder: "State",
      children: getStateListingEle(),
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value: companyDetails?.addresses?.[showCompanyAddressInfoKey]?.state,
    },
    {
      type: "dropdown",
      label: "City",
      name: "city",
      placeholder: "City",
      children: getCityListingEle(),
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value: companyDetails?.addresses?.[showCompanyAddressInfoKey]?.city,
    },
    {
      type: "dropdown",
      label: "District",
      name: "district",
      placeholder: "District",
      children: getDistrictListingEle(),
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value: companyDetails?.addresses?.[showCompanyAddressInfoKey]?.district,
    },
    {
      type: "dropdown",
      label: "Locality",
      name: "locality",
      placeholder: "Locality",
      children: getLocalityListingEle(),
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value: companyDetails?.addresses?.[showCompanyAddressInfoKey]?.locality,
    },

    {
      type: "inputNumber",
      label: "Postal Code",
      name: "postalCode",
      placeholder: "Postal Code",
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value: companyDetails?.addresses?.[showCompanyAddressInfoKey]?.postalCode,
    },
    {
      type: "dropdown",
      label: "Office Type",
      name: "officeType",
      placeholder: "Office Type",
      children: getOfficeTypeListingEle(),
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value: companyDetails?.addresses?.[showCompanyAddressInfoKey]?.officeType,
    },
    {
      type: "input",
      label: "Active Status",
      name: "activeStatus",
      placeholder: "Active Status",
      disabled: true,
      value:
        companyDetails?.addresses?.[showCompanyAddressInfoKey]?.activeStatus,
    },
    {
      type: "datepicker",
      label: "Establishment Date",
      name: "establishmentDate",
      placeholder: "DD-MM-YYYY",
      disabled:
        isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
        isCompanyAddressInfoEditable === null,
      value: companyDetails?.addresses?.[showCompanyAddressInfoKey]
        ?.establishmentDate
        ? dayjs(
          companyDetails?.addresses?.[showCompanyAddressInfoKey]
            ?.establishmentDate
        )
        : null,
      defaultValue: companyDetails?.addresses?.[showCompanyAddressInfoKey]
        ?.establishmentDate
        ? dayjs(
          companyDetails?.addresses?.[showCompanyAddressInfoKey]
            ?.establishmentDate
        )
        : null,
    },
    {
      type: "input",
      label: "Migration Flag",
      name: "migrationFlag",
      placeholder: "Migration Flag",
      disabled: true,
      value:
        companyDetails?.addresses?.[showCompanyAddressInfoKey]?.migrationFlag,
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(setCompanyInfoEditable(false));
      dispatch(getCompanyDetailsData(id));
    } else dispatch(setCompanyInfoEditable(true));

    if (navigatedFrom === "Transaction") {
      dispatch(setCompanyInfoEditable(true));
      dispatch(setCompanyInitialState());
    }

    dispatch(getAllDistinctCountryMaster());
  }, []);

  useEffect(() => {
    if (!companyId) return;
    dispatch(setCompanyInfoEditable(false));
    dispatch(getCompanyDetailsData(companyId));
  }, [companyId]);

  useEffect(() => {
    setFileList(companyDetails?.companyImages);
    let companyType = companyTypeOptions.filter(
      (item) => item.key === companyDetails?.companyType
    );
    setCinForCompanyType(companyType?.[0]?.value ?? null);
  }, [companyDetails]);

  useEffect(() => {
    let companyLogo = [];
    if (companyDetails?.companyLogo) {
      companyLogo = [...companyDetails?.companyLogo];
    }
    setLogo(companyLogo);
  }, [companyDetails?.companyLogo]);

  useEffect(() => {
    let companyImages = [];
    if (companyDetails?.companyImages) {
      companyImages = [...companyDetails?.companyImages];
    }
    setFileList(companyImages);
  }, [companyDetails?.companyImages]);

  const handleDeleteCompanyAddressInfo = (type, index) => {
    let companyAddressInfo = [...companyDetails?.addresses];
    companyAddressInfo?.splice(index, 1);
    dispatch(
      setCompanyInfo({
        name: "addresses",
        value: companyAddressInfo,
      })
    );
    setSavedAddressCnt(savedAddressCnt - 1);
  };

  const handleSaveCompanyAddressInfo = () => {
    setIsCompanyAddressInfoEditable(null);
    setShowCompanyAddressInfoKey(null);
  };

  const handleChangeCompanyAddressInfo = (element, e, dateVal, index) => {
    if (element?.name === "country") {
      dispatch(getAllDistinctStateByCountry(e));
    } else if (element?.name === "state") {
      dispatch(getAllDistinctCityByState(e));
    } else if (element?.name === "city") {
      dispatch(getAllDistinctDistrictByCity(e));
    } else if (element?.name === "district") {
      dispatch(getAllDistinctLocalityByDistrict(e));
    }
    const companyAddressData = [...companyDetails?.addresses];
    const addressInfo = {
      ...companyDetails?.addresses?.[index],
    };
    if (element?.type === "datepicker") {
      addressInfo[element.name] = dateVal
        ? dayjs(dateVal).add(5, "hour").add(30, "minute")
        : "";
    } else if (element?.type === "inputNumber" && e !== null) {
      addressInfo[element.name] = e;
    } else {
      if (e !== null) {
        if (element?.type === "dropdown") {
          addressInfo[element.name] = e;
        } else addressInfo[element.name] = e.target.value;
      }
    }
    companyAddressData[index] = addressInfo;
    dispatch(
      setCompanyInfo({
        name: "addresses",
        value: companyAddressData,
      })
    );
  };

  useEffect(() => {
    const mappedData = companyDetails?.addresses?.map((item, index) => {
      return {
        key: index,
        label: item?.addressType,
        children: (
          <React.Fragment>
            <div style={{ float: "right" }}>
              {(isCompanyAddressInfoEditable !== showCompanyAddressInfoKey ||
                isCompanyAddressInfoEditable === null) && (
                  <Button
                    onClick={() => setIsCompanyAddressInfoEditable(index)}
                    disabled={!isCompanyInfoEditable}
                  >
                    Edit
                  </Button>
                )}
              &nbsp;&nbsp;
              {isCompanyAddressInfoEditable === showCompanyAddressInfoKey && (
                <Button
                  onClick={() =>
                    handleSaveCompanyAddressInfo("addresses", index)
                  }
                  disabled={!isCompanyInfoEditable}
                >
                  Save
                </Button>
              )}
              &nbsp;&nbsp;
              <Button
                onClick={() =>
                  handleDeleteCompanyAddressInfo("addresses", index)
                }
                disabled={!isCompanyInfoEditable}
              >
                Delete
              </Button>
            </div>
            <Row
              span={12}
              style={{
                justifyContent: "space-between",
                marginRight: "150px",
              }}
            >
              <Col span={6}>
                {companyAddressInfoFormElementsCol.map((element, i) => {
                  if (i % 3 === 0) {
                    return (
                      <FormElement
                        key={`${element?.name}-${index}`}
                        {...element}
                        onChange={(e, dateVal) => {
                          handleChangeCompanyAddressInfo(
                            element,
                            e,
                            dateVal,
                            index
                          );
                        }}
                      />
                    );
                  }
                })}
              </Col>
              <Col span={6}>
                {companyAddressInfoFormElementsCol.map((element, i) => {
                  if (
                    [
                      1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40, 43,
                      46, 49,
                    ].includes(i)
                  ) {
                    return (
                      <FormElement
                        key={`${element?.name}-${index}`}
                        {...element}
                        onChange={(e, dateVal) => {
                          handleChangeCompanyAddressInfo(
                            element,
                            e,
                            dateVal,
                            index
                          );
                        }}
                      />
                    );
                  }
                })}
              </Col>
              <Col span={6}>
                {companyAddressInfoFormElementsCol.map((element, i) => {
                  if (
                    [
                      2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41, 44,
                      47, 50,
                    ].includes(i)
                  ) {
                    return (
                      <FormElement
                        key={`${element?.name}-${index}`}
                        {...element}
                        onChange={(e, dateVal) => {
                          handleChangeCompanyAddressInfo(
                            element,
                            e,
                            dateVal,
                            index
                          );
                        }}
                      />
                    );
                  }
                })}
              </Col>
            </Row>
          </React.Fragment>
        ),
      };
    });
    setSavedAddressList(mappedData);
  }, [
    savedAddressCnt,
    showCompanyAddressInfoKey,
    isCompanyAddressInfoEditable,
    companyDetails,
    isCompanyInfoEditable,
    countryMaster,
    stateMaster,
    cityMaster,
    districtMaster,
    localityMaster,
  ]);

  const saveCompanyInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Company Created successfully",
    });
  };

  const saveCompanyInfoErrorMessage = (error) => {
    messageApi.open({
      type: "warning",
      content: error || "Something went wrong",
    });
  };

  const addressTypeWarningMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please select Address Type",
    });
  };

  const updateCompanyInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Company Updated successfully",
    });
  };

  const companyInfoValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please check field validation",
    });
  };

  const cinValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Company with entered CIN already present",
    });
  };

  const addressTypes = [
    { value: null, label: "Select Address Type" },
    {
      value: "Foreign Registered Address",
      label: "Foreign Registered Address",
    },
    { value: "Registered Address", label: "Registered Address" },
    {
      value: "Police Station Address",
      label: "Police Station Address",
    },
    { value: "Permanent Address", label: "Permanent Address" },
    {
      value: "Other Address in India",
      label: "Other Address in India",
    },
    {
      value: "Book of Account",
      label: "Book of Account",
    },
    {
      value: "Bill To Address",
      label: "Bill To Address",
    },
    {
      value: "Present Address",
      label: "Present Address",
    },
  ];

  const companyInfoFormElementsCol = [
    {
      type: "dropdown",
      label: "Company Type",
      name: "companyType",
      placeholder: "Company Type",
      children: getCompanyTypeListingEle(),
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.companyType,
    },
    {
      type: "input",
      label: cinForCompanyType === null ? "CIN" : cinForCompanyType,
      name: "cin",
      required: cinForCompanyType === null || cinForCompanyType !== "-",
      placeholder: cinForCompanyType === null ? "CIN" : cinForCompanyType,
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.cin,
    },
    {
      type: "input",
      label: "Company Name",
      required: true,
      name: "company",
      placeholder: "Company Name",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.company,
    },
    {
      type: "dropdown",
      label: "Company Origin",
      name: "companyOrigin",
      placeholder: "Company Origin",
      children: getCompanyOriginListingEle(),
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.companyOrigin,
    },
    {
      type: "inputNumber",
      label: "Registration Number",
      name: "registrationNumber",
      placeholder: "Registration Number",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.registrationNumber,
    },
    {
      type: "datepicker",
      label: "Date of Incorporation",
      name: "dateOfIncorporation",
      placeholder: "DD-MM-YYYY",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.dateOfIncorporation
        ? dayjs(companyDetails?.dateOfIncorporation)
        : "",
      defaultValue: companyDetails?.dateOfIncorporation
        ? dayjs(companyDetails?.dateOfIncorporation)
        : "",
    },
    {
      type: "input",
      label: "Email Address",
      name: "emailAddress",
      placeholder: "Email Address",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.emailAddress,
    },
    {
      type: "dropdown",
      label: "Whether listed or not",
      name: "whetherListedOrNot",
      placeholder: "Whether listed or not",
      children: getCompanyListedFlagListingEle(),
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.whetherListedOrNot,
    },
    {
      type: "dropdown",
      label: "Company Category",
      name: "companyCategory",
      placeholder: "Company Category",
      children: getCompanyCategoryListingEle(),
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.companyCategory,
    },
    {
      type: "dropdown",
      label: "Company Sub Category",
      name: "companySubCategory",
      placeholder: "Company Sub Category",
      children: getCompanySubCategoryListingEle(),
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.companySubCategory,
    },
    {
      type: "dropdown",
      label: "Class of Company",
      name: "classOfCompany",
      placeholder: "Class of Company",
      children: getClassofCompanyListingEle(),
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.classOfCompany,
    },
    {
      type: "input",
      label: "Authorised Capital",
      name: "authorisedCapital",
      placeholder: "Authorised Capital",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.authorisedCapital,
    },
    {
      type: "input",
      label: "Paid Up Capital",
      name: "paidUpCapital",
      placeholder: "Paid Up Capital",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.paidUpCapital,
    },
    {
      type: "inputNumber",
      label: "Number of Members",
      name: "numberOfMembers",
      placeholder: "Number of Members",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.numberOfMembers,
    },
    {
      type: "datepicker",
      label: "Date of Last AGM",
      name: "dateOfLastAGM",
      placeholder: "DD-MM-YYYY",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.dateOfLastAGM
        ? dayjs(companyDetails?.dateOfLastAGM)
        : null,
      defaultValue: companyDetails?.dateOfLastAGM
        ? dayjs(companyDetails?.dateOfLastAGM)
        : null,
    },
    {
      type: "datepicker",
      label: "Strike of Amalgamated Transferred Date",
      name: "strikeOfAmalgamatedTransferredDate",
      placeholder: "DD-MM-YYYY",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.strikeOfAmalgamatedTransferredDate
        ? dayjs(companyDetails?.strikeOfAmalgamatedTransferredDate)
        : null,
      defaultValue: companyDetails?.strikeOfAmalgamatedTransferredDate
        ? dayjs(companyDetails?.strikeOfAmalgamatedTransferredDate)
        : null,
    },
    {
      type: "dropdown",
      label: "LLP Status",
      name: "llpStatus",
      placeholder: "LLP Status",
      children: getLLPStatusListingEle(),
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.llpStatus,
    },
    {
      type: "input",
      label: "Status Under CIRP",
      name: "statusUnderCIRP",
      placeholder: "Status Under CIRP",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.statusUnderCIRP,
    },
    {
      type: "inputNumber",
      label: "Number of Partners",
      name: "numberOfPartners",
      placeholder: "Number of Partners",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.numberOfPartners,
    },
    {
      type: "inputNumber",
      label: "Number of Designated Partners",
      name: "numberOfDesignatedPartners",
      placeholder: "Number of Designated Partners",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.numberOfDesignatedPartners,
    },
    {
      type: "input",
      label: "Previous Firm Company Details",
      name: "previousFirmCompanyDetails",
      placeholder: "Previous Firm Company Details",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.previousFirmCompanyDetails,
    },
    {
      type: "input",
      label: "Total Obligation of Contribution",
      name: "totalObligationOfContribution",
      placeholder: "Total Obligation of Contribution",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.totalObligationOfContribution,
    },
    {
      type: "input",
      label: "Main Division",
      name: "mainDivision",
      placeholder: "Main Division",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.mainDivision,
    },
    {
      type: "input",
      label: "Main Division Description",
      name: "mainDivisionDescription",
      placeholder: "Main Division Description",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.mainDivisionDescription,
    },
    {
      type: "datepicker",
      label: "Statement Date",
      name: "statementDate",
      placeholder: "Statement Date",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.statementDate
        ? dayjs(companyDetails?.statementDate)
        : null,
      defaultValue: companyDetails?.statementDate
        ? dayjs(companyDetails?.statementDate)
        : null,
    },
    {
      type: "inputNumber",
      label: "BS Default 2Yrs",
      name: "bsDefault2Yrs",
      placeholder: "BS Default 2Yrs",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.bsDefault2Yrs,
    },
    {
      type: "inputNumber",
      label: "BS Default 3Yrs",
      name: "bsDefault3Yrs",
      placeholder: "BS Default 3Yrs",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.bsDefault3Yrs,
    },
    {
      type: "inputNumber",
      label: "AR Default 2Yrs",
      name: "arDefault2Yrs",
      placeholder: "AR Default 2Yrs",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.arDefault2Yrs,
    },
    {
      type: "inputNumber",
      label: "AR Default 3Yrs",
      name: "arDefault3Yrs",
      placeholder: "AR Default 3Yrs",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.arDefault3Yrs,
    },
    {
      type: "input",
      label: "Suspended At Stock Exchange",
      name: "suspendedAtStockExchange",
      placeholder: "Suspended At Stock Exchange",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.suspendedAtStockExchange,
    },
    {
      type: "input",
      label: "ROC Name",
      name: "rocName",
      placeholder: "ROC Name",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.rocName,
    },
    {
      type: "input",
      label: "Share Capital Flag",
      name: "shareCapitalFlag",
      placeholder: "Share Capital Flag",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.shareCapitalFlag,
    },
    {
      type: "inputNumber",
      label: "Maximum Number of Members",
      name: "maximumNumberOfMembers",
      placeholder: "Maximum Number of Members",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.maximumNumberOfMembers,
    },
    {
      type: "inputNumber",
      label: "Subscribed Capital",
      name: "subscribedCapital",
      placeholder: "Subscribed Capital",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.subscribedCapital,
    },
    {
      type: "dropdown",
      label: "RD Name",
      name: "rdName",
      placeholder: "RD Name",
      children: getRDNameListingEle(),
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.rdName,
    },
    {
      type: "dropdown",
      label: "RD Region",
      name: "rdRegion",
      placeholder: "RD Region",
      children: getRDRegionListingEle(),
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.rdRegion,
    },
    {
      type: "datepicker",
      label: "Balance Sheet Date",
      name: "balanceSheetDate",
      placeholder: "Balance Sheet Date",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.balanceSheetDate
        ? dayjs(companyDetails?.balanceSheetDate)
        : null,
      defaultValue: companyDetails?.balanceSheetDate
        ? dayjs(companyDetails?.balanceSheetDate)
        : null,
    },
    {
      type: "input",
      label: "INC 22A Flag",
      name: "inc22AFlag",
      placeholder: "INC 22A Flag",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.inc22AFlag,
    },
    {
      type: "input",
      label: "Activity Code",
      name: "activityCode",
      placeholder: "Activity Code",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.activityCode,
    },
    {
      type: "input",
      label: "Activity Value",
      name: "activityValue",
      placeholder: "Activity Value",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.activityValue,
    },
    {},
    {},
    {
      type: "datepicker",
      label: "Data Of Filling",
      name: "dataOfFilling1OfBS",
      placeholder: "Data Of Filling",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.dataOfFilling1OfBS
        ? dayjs(companyDetails?.dataOfFilling1OfBS)
        : null,
      defaultValue: companyDetails?.dataOfFilling1OfBS
        ? dayjs(companyDetails?.dataOfFilling1OfBS)
        : null,
    },
    {
      type: "datepicker",
      label: "Data Of Filling",
      name: "dataOfFilling2OfBS",
      placeholder: "Data Of Filling",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.dataOfFilling2OfBS
        ? dayjs(companyDetails?.dataOfFilling2OfBS)
        : null,
      defaultValue: companyDetails?.dataOfFilling2OfBS
        ? dayjs(companyDetails?.dataOfFilling2OfBS)
        : null,
    },
    {
      type: "datepicker",
      label: "Data Of Filling",
      name: "dataOfFilling3OfBS",
      placeholder: "Data Of Filling",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.dataOfFilling3OfBS
        ? dayjs(companyDetails?.dataOfFilling3OfBS)
        : null,
      defaultValue: companyDetails?.dataOfFilling3OfBS
        ? dayjs(companyDetails?.dataOfFilling3OfBS)
        : null,
    },
    {
      type: "input",
      label: "Financial Year",
      name: "financialYear1OfBS",
      placeholder: "Financial Year",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.financialYear1OfBS,
    },
    {
      type: "input",
      label: "Financial Year",
      name: "financialYear2OfBS",
      placeholder: "Financial Year",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.financialYear2OfBS,
    },
    {
      type: "input",
      label: "Financial Year",
      name: "financialYear3OfBS",
      placeholder: "Financial Year",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.financialYear3OfBS,
    },
    {
      type: "datepicker",
      label: "Data Of Filling",
      name: "dataOfFilling1OfAR",
      placeholder: "Data Of Filling",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.dataOfFilling1OfAR
        ? dayjs(companyDetails?.dataOfFilling1OfAR)
        : null,
      defaultValue: companyDetails?.dataOfFilling1OfAR
        ? dayjs(companyDetails?.dataOfFilling1OfAR)
        : null,
    },
    {
      type: "datepicker",
      label: "Data Of Filling",
      name: "dataOfFilling2OfAR",
      placeholder: "Data Of Filling",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.dataOfFilling2OfAR
        ? dayjs(companyDetails?.dataOfFilling2OfAR)
        : null,
      defaultValue: companyDetails?.dataOfFilling2OfAR
        ? dayjs(companyDetails?.dataOfFilling2OfAR)
        : null,
    },
    {
      type: "datepicker",
      label: "Data Of Filling",
      name: "dataOfFilling3OfAR",
      placeholder: "Data Of Filling",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.dataOfFilling3OfAR,
      value: companyDetails?.dataOfFilling3OfAR
      ? dayjs(companyDetails?.dataOfFilling3OfAR)
      : null,
    defaultValue: companyDetails?.dataOfFilling3OfAR
      ? dayjs(companyDetails?.dataOfFilling3OfAR)
      : null,
    },
    {
      type: "input",
      label: "Financial Year",
      name: "financialYear1OfAR",
      placeholder: "Financial Year",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.financialYear1OfAR,
    },
    {
      type: "input",
      label: "Financial Year",
      name: "financialYear2OfAR",
      placeholder: "Financial Year",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.financialYear2OfAR,
    },
    {
      type: "input",
      label: "Financial Year",
      name: "financialYear3OfAR",
      placeholder: "Financial Year",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.financialYear3OfAR,
    },
    {
      type: "input",
      label: "PAN No.",
      name: "panNo",
      placeholder: "PAN No.",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.panNo,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isPAN(value)) {
              return Promise.reject("Please input correct PAN!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "GST No.",
      name: "gstNo",
      placeholder: "GST No.",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.gstNo,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isGST(value)) {
              return Promise.reject("Please input correct GST!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "Industry Type",
      name: "industryType",
      placeholder: "Industry Type",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.industryType,
    },
    {
      type: "input",
      label: "Description",
      name: "description",
      placeholder: "Description",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.description,
    },
    {
      type: "inputNumber",
      label: "Head Office Number",
      name: "headOfficeNumber",
      placeholder: "Head Office Number",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.headOfficeNumber,
    },
    {
      type: "input",
      label: "Website Link",
      name: "websiteLink",
      placeholder: "Website Link",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.websiteLink,
    },
    {
      type: "input",
      label: "Head Office Location",
      name: "headOfficeLocation",
      placeholder: "Head Office Location",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.headOfficeLocation,
    },
    {
      type: "input",
      label: "Company Strength",
      name: "companyStrength",
      placeholder: "Company Strength",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.companyStrength,
    },
    {
      type: "input",
      label: "Key Offerings",
      name: "keyOfferings",
      placeholder: "Key Offerings",
      disabled: !isCompanyInfoEditable,
      value: companyDetails?.keyOfferings,
    },
  ];

  const getUpdateCompanyPayload = (companyDetails) => {
    const formData = new FormData();
    if (
      companyDetails?.companyLogo &&
      companyDetails?.companyLogo?.length > 0
    ) {
      if (companyDetails?.companyLogo?.[0]?.originFileObj) {
        formData.append(
          "images",
          companyDetails?.companyLogo?.[0]?.originFileObj
        );
      }
    }
    if (
      companyDetails?.companyImages &&
      companyDetails?.companyImages?.length > 0
    ) {
      for (
        let index = 0;
        index < companyDetails?.companyImages.length;
        index++
      ) {
        if (companyDetails?.companyImages?.[index]?.originFileObj) {
          formData.append(
            "images",
            companyDetails?.companyImages?.[index]?.originFileObj
          );
        }
      }
    }

    const balanceSheet3Yrs = [];
    if (
      companyDetails?.dataOfFilling1OfBS ||
      companyDetails?.dataOfFilling2OfBS ||
      companyDetails?.dataOfFilling3OfBS ||
      companyDetails?.financialYear1OfBS ||
      companyDetails?.financialYear2OfBS ||
      companyDetails?.financialYear3OfBS
    ) {
      balanceSheet3Yrs.push({
        dataOfFilling: companyDetails?.dataOfFilling1OfBS,
        financialYear: companyDetails?.financialYear1OfBS,
      });
      balanceSheet3Yrs.push({
        dataOfFilling: companyDetails?.dataOfFilling2OfBS,
        financialYear: companyDetails?.financialYear2OfBS,
      });
      balanceSheet3Yrs.push({
        dataOfFilling: companyDetails?.dataOfFilling3OfBS,
        financialYear: companyDetails?.financialYear3OfBS,
      });
    }
    const annualReturn3Yrs = [];
    if (
      companyDetails?.dataOfFilling1OfAR ||
      companyDetails?.dataOfFilling2OfAR ||
      companyDetails?.dataOfFilling3OfAR ||
      companyDetails?.financialYear1OfAR ||
      companyDetails?.financialYear2OfAR ||
      companyDetails?.financialYear3OfAR
    ) {
      annualReturn3Yrs.push({
        dataOfFilling: companyDetails?.dataOfFilling1OfAR,
        financialYear: companyDetails?.financialYear1OfAR,
      });
      annualReturn3Yrs.push({
        dataOfFilling: companyDetails?.dataOfFilling2OfAR,
        financialYear: companyDetails?.financialYear2OfAR,
      });
      annualReturn3Yrs.push({
        dataOfFilling: companyDetails?.dataOfFilling3OfAR,
        financialYear: companyDetails?.financialYear3OfAR,
      });
    }

    let filteredDeletedFiles = deletedFileList.filter(Boolean);
    companyDetails?.companyLogo &&
      typeof companyDetails?.companyLogo === "object" &&
      companyDetails?.companyLogo?.length > 0 &&
      formData.append("companyLogo", companyDetails?.companyLogo?.[0]?.name);
    deletedFileList &&
      formData.append("deletedFiles", JSON.stringify(filteredDeletedFiles));
    companyDetails?.cin && formData.append("cin", companyDetails?.cin.trim());
    companyDetails?.company &&
      formData.append("company", companyDetails?.company.trim());
    companyDetails?.companyType &&
      formData.append("companyType", companyDetails?.companyType);
    companyDetails?.companyOrigin &&
      formData.append("companyOrigin", companyDetails?.companyOrigin);
    companyDetails?.registrationNumber &&
      formData.append("registrationNumber", companyDetails?.registrationNumber);
    companyDetails?.dateOfIncorporation &&
      formData.append(
        "dateOfIncorporation",
        companyDetails?.dateOfIncorporation
      );
    companyDetails?.emailAddress &&
      formData.append("emailAddress", companyDetails?.emailAddress);
    companyDetails?.whetherListedOrNot &&
      formData.append("whetherListedOrNot", companyDetails?.whetherListedOrNot);
    companyDetails?.companyCategory &&
      formData.append("companyCategory", companyDetails?.companyCategory);
    companyDetails?.companySubCategory &&
      formData.append("companySubCategory", companyDetails?.companySubCategory);
    companyDetails?.classOfCompany &&
      formData.append("classOfCompany", companyDetails?.classOfCompany);
    companyDetails?.authorisedCapital &&
      formData.append("authorisedCapital", companyDetails?.authorisedCapital);
    companyDetails?.paidUpCapital &&
      formData.append("paidUpCapital", companyDetails?.paidUpCapital);
    companyDetails?.numberOfMembers &&
      formData.append("numberOfMembers", companyDetails?.numberOfMembers);
    companyDetails?.dateOfLastAGM &&
      formData.append("dateOfLastAGM", companyDetails?.dateOfLastAGM);
    companyDetails?.strikeOfAmalgamatedTransferredDate &&
      formData.append(
        "strikeOfAmalgamatedTransferredDate",
        companyDetails?.strikeOfAmalgamatedTransferredDate
      );
    companyDetails?.llpStatus &&
      formData.append("llpStatus", companyDetails?.llpStatus);
    companyDetails?.statusUnderCIRP &&
      formData.append("statusUnderCIRP", companyDetails?.statusUnderCIRP);
    companyDetails?.numberOfPartners &&
      formData.append("numberOfPartners", companyDetails?.numberOfPartners);
    companyDetails?.numberOfDesignatedPartners &&
      formData.append(
        "numberOfDesignatedPartners",
        companyDetails?.numberOfDesignatedPartners
      );
    companyDetails?.previousFirmCompanyDetails &&
      formData.append(
        "previousFirmCompanyDetails",
        companyDetails?.previousFirmCompanyDetails
      );
    companyDetails?.totalObligationOfContribution &&
      formData.append(
        "totalObligationOfContribution",
        companyDetails?.totalObligationOfContribution
      );
    companyDetails?.mainDivision &&
      formData.append("mainDivision", companyDetails?.mainDivision);
    companyDetails?.mainDivisionDescription &&
      formData.append(
        "mainDivisionDescription",
        companyDetails?.mainDivisionDescription
      );
    companyDetails?.statementDate &&
      formData.append("statementDate", companyDetails?.statementDate);
    companyDetails?.bsDefault2Yrs &&
      formData.append("bsDefault2Yrs", companyDetails?.bsDefault2Yrs);
    companyDetails?.bsDefault3Yrs &&
      formData.append("bsDefault3Yrs", companyDetails?.bsDefault3Yrs);
    companyDetails?.arDefault2Yrs &&
      formData.append("arDefault2Yrs", companyDetails?.arDefault2Yrs);
    companyDetails?.arDefault3Yrs &&
      formData.append("arDefault3Yrs", companyDetails?.arDefault3Yrs);
    companyDetails?.suspendedAtStockExchange &&
      formData.append(
        "suspendedAtStockExchange",
        companyDetails?.suspendedAtStockExchange
      );
    companyDetails?.rocName &&
      formData.append("rocName", companyDetails?.rocName);
    companyDetails?.shareCapitalFlag &&
      formData.append("shareCapitalFlag", companyDetails?.shareCapitalFlag);
    companyDetails?.maximumNumberOfMembers &&
      formData.append(
        "maximumNumberOfMembers",
        companyDetails?.maximumNumberOfMembers
      );
    companyDetails?.subscribedCapital &&
      formData.append("subscribedCapital", companyDetails?.subscribedCapital);
    companyDetails?.rdName && formData.append("rdName", companyDetails?.rdName);
    companyDetails?.rdRegion &&
      formData.append("rdRegion", companyDetails?.rdRegion);
    companyDetails?.balanceSheetDate &&
      formData.append("balanceSheetDate", companyDetails?.balanceSheetDate);
    companyDetails?.inc22AFlag &&
      formData.append("inc22AFlag", companyDetails?.inc22AFlag);
    companyDetails?.activityCode &&
      formData.append("activityCode", companyDetails?.activityCode);
    companyDetails?.activityValue &&
      formData.append("activityValue", companyDetails?.activityValue);
    balanceSheet3Yrs &&
      formData.append("balanceSheet3Yrs", JSON.stringify(balanceSheet3Yrs));
    annualReturn3Yrs &&
      formData.append("annualReturns3Yrs", JSON.stringify(annualReturn3Yrs));
    companyDetails?.panNo && formData.append("panNo", companyDetails?.panNo);
    companyDetails?.gstNo && formData.append("gstNo", companyDetails?.gstNo);
    companyDetails?.industryType &&
      formData.append("industryType", companyDetails?.industryType);
    companyDetails?.description &&
      formData.append("description", companyDetails?.description);
    companyDetails?.headOfficeNumber &&
      formData.append("headOfficeNumber", companyDetails?.headOfficeNumber);
    companyDetails?.websiteLink &&
      formData.append("websiteLink", companyDetails?.websiteLink);
    companyDetails?.headOfficeLocation &&
      formData.append("headOfficeLocation", companyDetails?.headOfficeLocation);
    companyDetails?.companyStrength &&
      formData.append("companyStrength", companyDetails?.companyStrength);
    companyDetails?.keyOfferings &&
      formData.append("keyOfferings", companyDetails?.keyOfferings);
    companyDetails?.addresses &&
      formData.append("addresses", JSON.stringify(companyDetails?.addresses));
    return formData;
  };

  const handleChangeCompanyInfo = (element, e, dateVal) => {
    if (element?.name === "companyType") {
      setCinForCompanyType(dateVal?.companyType);
      form.setFieldsValue({
        ...form,
        cin: dateVal?.companyType === "-" ? null : companyDetails?.cin,
      });
      dispatch(
        setCompanyInfo({
          name: "cin",
          value: dateVal?.companyType === "-" ? null : companyDetails?.cin,
        })
      );
    }
    if (element?.type === "datepicker") {
      form.setFieldsValue({
        ...form,
        [element?.name]: dateVal
          ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute")
          : "",
      });
      dispatch(
        setCompanyInfo({
          name: element?.name,
          value: dateVal ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute") : "",
        })
      );
    } else if (element?.type === "inputNumber" && e !== null) {
      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      dispatch(
        setCompanyInfo({
          name: element?.name,
          value: e,
        })
      );
    } else {
      if (e !== null) {
        form.setFieldsValue({
          ...form,
          [element?.name]: element?.type === "dropdown" ? e : e.target.value,
        });
        dispatch(
          setCompanyInfo({
            name: element?.name,
            value: element?.type === "dropdown" ? e : e.target.value,
          })
        );
      }
    }
  };

  const handleShowAddressInfo = (key, type) => {
    if (key) {
      setShowCompanyAddressInfoKey(Number(key));
      return;
    }
    if (key === undefined && type === "addresses") {
      setShowCompanyAddressInfoKey(0);
      setIsCompanyAddressInfoEditable(null);
    }
  };

  const handleDeleteLogo = (fileToDelete) => {
    if (isCompanyInfoEditable) {
      const deletedFiles = [...deletedFileList, logo?.[0]?.url];

      setDeletedFileList(deletedFiles);
      setLogo([]);

      dispatch(
        setCompanyInfo({
          name: "companyLogo",
          value: [],
        })
      );
    }
  };

  const handleDeleteImages = (fileToDelete) => {
    if (isCompanyInfoEditable) {
      const indexToDelete = fileList.findIndex(
        (file) => file.uid === fileToDelete.uid
      );
      const deletedFiles = [...deletedFileList, fileList[indexToDelete].url];

      setDeletedFileList(deletedFiles);

      if (indexToDelete !== -1) {
        const newFileList = fileList.filter(
          (_, index) => index !== indexToDelete
        );

        setFileList(newFileList);

        dispatch(
          setCompanyInfo({
            name: "companyImages",
            value: newFileList,
          })
        );
      }
    }
  };

  const logoProps = {
    onRemove: handleDeleteLogo,
    beforeUpload: (file) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isAllowed = allowedTypes.includes(file.type);
      if (!isAllowed) {
        message.error("You can only upload JPG, JPEG, or PNG files!");
        return false;
      }
      const uuid = uuidv4(); // Generate UUID for the file
      file.uid = uuid; // Assign UUID as unique identifier for Ant Design Upload
      file.name = `${uuid}-${file.name}`; // Append UUID to the file name
      setLogo([...logo, file]);
      return file;
    },
    onChange: (info) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isAllowed = allowedTypes.includes(info.file.type);
      if (!isAllowed) return;
      const currFile = info.fileList.filter(
        (file) => file.uid === info.file.uid
      );
      dispatch(
        setCompanyInfo({
          name: "companyLogo",
          value: [currFile[0]],
        })
      );
    },
  };

  const props = {
    onRemove: handleDeleteImages,
    beforeUpload: (file) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isAllowed = allowedTypes.includes(file.type);
      if (!isAllowed) {
        message.error("You can only upload JPG, JPEG, or PNG files!");
        return false;
      }
      const uuid = uuidv4(); // Generate UUID for the file
      file.uid = uuid; // Assign UUID as unique identifier for Ant Design Upload
      file.name = `${uuid}-${file.name}`; // Append UUID to the file name
      setFileList([...fileList, file]);
      return file;
    },
    onChange: (info) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isAllowed = allowedTypes.includes(info.file.type);
      if (!isAllowed) return;
      const currFile = info.fileList.filter(
        (file) => file.uid === info.file.uid
      );
      dispatch(
        setCompanyInfo({
          name: "companyImages",
          value: [...(companyDetails?.companyImages || []), currFile[0]],
        })
      );
    },
  };

  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          {contextHolder}
          <div className="w-100 h-16 bg-orange-400 flex align-center items-center p-4">
            <Header />
          </div>
          <div style={{ padding: "50px" }}>
            <Form
              form={form}
              name="basic"
              onFinish={async () => {
                if (cinForCompanyType === "-") {
                  dispatch(validateCIN(companyDetails?.cin))
                    .unwrap()
                    .then((data) => {
                      if (
                        data?.data?.result === true ||
                        (data?.data?.result === false &&
                          data?.data?.companies?.length === 1 &&
                          data?.data?.companies?.[0]?.company.trim() ===
                          companyDetails?.company.trim())
                      ) {
                        if (isCompanyInfoEditable === true && id) {
                          dispatch(
                            updateCompanyInfo(
                              getUpdateCompanyPayload(companyDetails)
                            )
                          );
                          updateCompanyInfoSuccessMessage();
                          setTimeout(() => {
                            dispatch(setLoading(true));
                            navigate("/dashboard");
                            dispatch(setCompanyInfoEditable(false));
                            dispatch(setCompanyId(null));
                            dispatch(setCompanyInitialState());
                          }, 1000);
                        } else if (isCompanyInfoEditable === true && !id) {
                          dispatch(
                            saveCompanyInfo(
                              getUpdateCompanyPayload(companyDetails)
                            )
                          )
                          .unwrap()
                          .then(data => {
                            if(data?.status === 201){
                              saveCompanyInfoSuccessMessage();
                              setTimeout(() => {
                                dispatch(setLoading(false));
                                dispatch(setCompanyInfoEditable(false));
                                if (navigatedFrom === "Connect") {
                                  dispatch(setCompanyId(null));
                                  dispatch(setCompanyInitialState());
                                  if (connectId) {
                                    navigate(`/connectDetails/${connectId}`);
                                  } else {
                                    navigate("/connectDetails");
                                  }
                                } else if (navigatedFrom === "Transaction") {
                                  dispatch(setCompanyId(null));
                                  dispatch(setCompanyInitialState());
                                  if (transactionId) {
                                    navigate(
                                      `/transactionDetails/${transactionId}`
                                    );
                                  } else {
                                    navigate("/transactionDetails");
                                  }
                                } else {
                                  navigate("/dashboard");
                                }
                              }, 1000);
                            }else{
                              saveCompanyInfoErrorMessage(data?.response?.data?.error);
                            }
                          }) 
                        }
                      } else if (data?.data?.result === false) {
                        cinValidationMessage();
                      }
                    });
                } else {
                  dispatch(validateCIN(companyDetails?.cin))
                    .unwrap()
                    .then((data) => {
                      if (
                        data?.data?.result === true ||
                        (data?.data?.result === false &&
                          data?.data?.companies?.length === 1 &&
                          data?.data?.companies?.[0]?.company ===
                          companyDetails?.company)
                      ) {
                        if (isCompanyInfoEditable === true && id) {
                          dispatch(
                            updateCompanyInfo(
                              getUpdateCompanyPayload(companyDetails)
                            )
                          );
                          updateCompanyInfoSuccessMessage();
                          setTimeout(() => {
                            dispatch(setLoading(true));
                            navigate("/dashboard");
                            dispatch(setCompanyInfoEditable(false));
                            dispatch(setCompanyId(null));
                            dispatch(setCompanyInitialState());
                          }, 1000);
                        } else if (isCompanyInfoEditable === true && !id) {
                          dispatch(
                            saveCompanyInfo(
                              getUpdateCompanyPayload(companyDetails)
                            )
                          )
                          .unwrap()
                          .then(data => {
                            if(data?.status === 201){
                              saveCompanyInfoSuccessMessage();
                              setTimeout(() => {
                                dispatch(setLoading(false));
                                dispatch(setCompanyInfoEditable(false));
                                if (navigatedFrom === "Connect") {
                                  dispatch(setCompanyId(null));
                                  dispatch(setCompanyInitialState());
                                  if (connectId) {
                                    navigate(`/connectDetails/${connectId}`);
                                  } else {
                                    navigate("/connectDetails");
                                  }
                                } else if (navigatedFrom === "Transaction") {
                                  dispatch(setCompanyId(null));
                                  dispatch(setCompanyInitialState());
                                  if (transactionId) {
                                    navigate(
                                      `/transactionDetails/${transactionId}`
                                    );
                                  } else {
                                    navigate("/transactionDetails");
                                  }
                                } else {
                                  navigate("/dashboard");
                                }
                              }, 1000);
                            }else{
                              saveCompanyInfoErrorMessage(data?.response?.data?.error)
                            }
                          })
                        }
                      } else if (data?.data?.result === false) {
                        cinValidationMessage();
                      }
                    });
                }
              }}
              onFinishFailed={() => {
                companyInfoValidationMessage();
              }}
            >
              <div style={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
                padding: "10px",
                marginBottom: "20px",
                width: "100%",
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "white",
                boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
              }}>
                <Title level={4}>View Company Information</Title>
                <div>
                  {showCreateDirectorBtn && (
                    <React.Fragment>
                      <Button
                        onClick={() => {
                          navigate("/directorDetails");
                        }}
                      >
                        Add Director
                      </Button>
                      &nbsp;&nbsp;
                    </React.Fragment>
                  )}
                  {!showCreateDirectorBtn && (
                    <Fragment>
                      {isCompanyInfoEditable === true && id && (
                        <Button htmlType="submit">Update Company</Button>
                      )}
                      {isCompanyInfoEditable === true && !id && (
                        <Button htmlType="submit"> Save Company</Button>
                      )}
                      {/* {isCompanyInfoEditable === false && id && (
                        <VerifySwitch />
                      )} */}
                      &nbsp;&nbsp;
                      {isCompanyInfoEditable === false && (
                        <Button
                          onClick={() => dispatch(setCompanyInfoEditable(true))}
                        >
                          Edit Company
                        </Button>
                      )}
                    </Fragment>
                  )}
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      navigate("/dashboard");
                      dispatch(setCompanyInfoEditable(false));
                      dispatch(setCompanyId(null));
                      dispatch(setCompanyInitialState());
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
              <Row
                span={12}
                style={{
                  justifyContent: "space-between",
                  marginRight: "150px",
                }}
              >
                <Col span={6}>
                  {companyInfoFormElementsCol.map((element, index) => {
                    if (index % 3 === 0) {
                      return (
                        <>
                          {index === 42 ? (
                            <h3
                              style={{
                                marginBottom: "5px",
                                fontSize: "1rem",
                              }}
                            >
                              Balance Sheet 3 years
                            </h3>
                          ) : null}
                          {index === 48 ? (
                            <h3
                              style={{
                                marginBottom: "5px",
                                fontSize: "1rem",
                              }}
                            >
                              Annual Returns 3 years
                            </h3>
                          ) : null}
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeCompanyInfo(element, e, dateVal);
                            }}
                          />
                        </>
                      );
                    }
                  })}
                </Col>
                <Col span={6}>
                  {companyInfoFormElementsCol.map((element, index) => {
                    if (
                      [
                        1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40, 43,
                        46, 49, 52, 55, 58, 61, 64, 67, 70,
                      ].includes(index)
                    ) {
                      return (
                        <>
                          {index === 49 ? (
                            <div style={{ padding: "13px" }}></div>
                          ) : null}
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeCompanyInfo(element, e, dateVal);
                            }}
                          />
                        </>
                      );
                    }
                  })}
                </Col>
                <Col span={6}>
                  {companyInfoFormElementsCol.map((element, index) => {
                    if (
                      [
                        2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41, 44,
                        47, 50, 53, 56, 59, 62, 65,
                      ].includes(index)
                    ) {
                      return (
                        <>
                          {index === 50 ? (
                            <div style={{ padding: "13px" }}></div>
                          ) : null}
                          <FormElement
                            key={index}
                            {...element}
                            onChange={(e, dateVal) => {
                              handleChangeCompanyInfo(element, e, dateVal);
                            }}
                          />
                        </>
                      );
                    }
                  })}
                </Col>
              </Row>
              <br />
              <Title level={4}>Address Information</Title>
              <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
              <div style={{ display: "flex" }}>
                <div>
                  <Select
                    defaultValue={null}
                    value={selectedAddressType}
                    style={{ width: 240 }}
                    onChange={(val) => {
                      if (savedAddressList && isAddressTypePresent(val)) {
                        message.error(
                          "Address with selected Address Type is already added. Please select other Address Type"
                        );
                      } else {
                        setSelectedAddressType(val);
                        setShowAddressTypeValidation(false);
                      }
                    }}
                    options={addressTypes}
                    disabled={!isCompanyInfoEditable}
                  />
                  <br />
                  {showAddressTypeValidation && (
                    <Text style={{ color: "red" }}>
                      Please select Address Type!
                    </Text>
                  )}
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  onClick={() => {
                    if (selectedAddressType) {
                      if (
                        savedAddressList &&
                        isAddressTypePresent(selectedAddressType)
                      ) {
                        message.error(
                          "Address with selected Address Type is already added. Please select other Address Type"
                        );
                      } else {
                        dispatch(
                          setCompanyInfo({
                            name: "newAddress",
                            value: {},
                            addressType: selectedAddressType,
                          })
                        );
                        setSavedAddressCnt(savedAddressCnt + 1);
                        setIsCompanyAddressInfoEditable(
                          savedAddressCnt === 0 ? 0 : savedAddressCnt + 1
                        );
                        setShowCompanyAddressInfoKey(
                          savedAddressCnt === 0 ? 0 : savedAddressCnt + 1
                        );
                      }
                    } else addressTypeWarningMessage();
                  }}
                  disabled={!isCompanyInfoEditable}
                >
                  Add
                </Button>
              </div>
              <br />
              <br />
              {savedAddressList?.length > 0 && (
                <Collapse
                  accordion
                  items={savedAddressList}
                  defaultActiveKey={[showCompanyAddressInfoKey]}
                  activeKey={[showCompanyAddressInfoKey]}
                  onChange={(key) =>
                    handleShowAddressInfo(key?.[0], "addresses")
                  }
                  style={{ marginBottom: "20px" }}
                />
              )}
            </Form>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title level={4}>Assets</Title>
            </div>
            <Title level={5}>Note: Extensions allowed - jpg, png, jpeg</Title>
            <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
            <Row span={12} style={{ columnGap: "10px", marginRight: "150px" }}>
              {isCompanyInfoEditable && (
                <Upload
                  {...props}
                  fileList={
                    isCompanyInfoEditable
                      ? companyDetails?.companyImages
                      : fileList
                  }
                  disabled={!isCompanyInfoEditable}
                  maxCount={10}
                  preview={false}
                  showUploadList={false}
                  multiple={false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    loading={uploading}
                    disabled={!isCompanyInfoEditable}
                  >
                    Select File
                  </Button>
                </Upload>
              )}
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {companyDetails?.companyImages?.length > 0 ? (
                    companyDetails?.companyImages.map((file, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          margin: "0px 10px 0px 10px",
                        }}
                      >
                        {isCompanyInfoEditable && (
                          <CloseOutlined
                            style={{
                              color: "black",
                              position: "absolute",
                              top: 3,
                              right: 5,
                              zIndex: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteImages(file)}
                          />
                        )}
                        {(file.originFileObj || file.url) && (
                          <Image
                            width={200}
                            height={150}
                            preview={false}
                            src={
                              file.url ||
                              URL.createObjectURL(file.originFileObj)
                            }
                            alt={`Uploaded image ${index + 1}`}
                            style={{ borderRadius: "8px" }}
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <>
                      {!isCompanyInfoEditable && <h2>No Assets to display</h2>}
                    </>
                  )}
                </div>
              </div>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <Title level={4}>Upload Logo</Title>
            </div>
            <Title level={5}>Note: Extensions allowed - jpg, png, jpeg</Title>
            <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
            <Row span={12} style={{ columnGap: "10px", marginRight: "150px" }}>
              {isCompanyInfoEditable && (
                <Upload
                  {...logoProps}
                  fileList={
                    isCompanyInfoEditable
                      ? companyDetails?.companyLogo
                      : fileList
                  }
                  disabled={!isCompanyInfoEditable}
                  preview={false}
                  maxCount={1}
                  showUploadList={false}
                  multiple={false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    loading={uploading}
                    disabled={!isCompanyInfoEditable}
                  >
                    Select File
                  </Button>
                </Upload>
              )}
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {companyDetails?.companyLogo?.length > 0 ? (
                    companyDetails?.companyLogo?.map((file, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          margin: "0px 10px 0px 10px",
                        }}
                      >
                        {isCompanyInfoEditable && (
                          <CloseOutlined
                            style={{
                              color: "black",
                              position: "absolute",
                              top: 3,
                              right: 5,
                              zIndex: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteLogo(file)}
                          />
                        )}
                        {(file.originFileObj || file.url) && (
                          <Image
                            width={200}
                            height={150}
                            preview={false}
                            src={
                              file.url ||
                              URL.createObjectURL(file.originFileObj)
                            }
                            alt={`Uploaded image ${index + 1}`}
                            style={{ borderRadius: "8px" }}
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <>{!isCompanyInfoEditable && <h2>No Logo to display</h2>}</>
                  )}
                </div>
              </div>
            </Row>
          </div>
        </>
      )}
    </>
  );
}

export default CompanyDetailsCard;
