import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Col,
  Row,
  Divider,
  Select,
  Button,
  Spin,
  Upload,
  message,
  Checkbox,
  Image,
} from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid"; // Import v4 function as uuidv4
import Title from "antd/es/typography/Title";
import FormElement from "../../components/formElement/FormElement";
// import VerifySwitch from "../../components/verifySwitch/VerifySwitch";
import Header from "../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  getPropertyDetailsData,
  setBuildingInfoEditable,
  setBuildingsInfo,
  setPropertyId,
  updateBuildingsInfo,
  setPropertyInitialState,
  saveBuildingsInfo,
  setLoading,
  getAllDistinctStateMaster,
  getAllDistinctCityByState,
  getAllDistinctMicroMarketByCity,
  getAllDistinctLocalityByCityAndMicromarket,
} from "../../feature/slice/PropertyDetailsSlice";
import {
  isLatitude,
  isLongitude,
  isPincode,
  isAadhar,
  isEmail,
  isMobileNumber,
  isPAN,
} from "../../utils/helpers";

const getAmenitiesListingEle = () => {
  let data = [
    {
      label: "Printing Facility",
      key: "Printing Facility",
    },
    {
      label: "Passenger Lifts",
      key: "Passenger Lifts",
    },
    {
      label: "24/7 Office Access",
      key: "24/7 Office Access",
    },
    {
      label: "Cafeteria",
      key: "Cafeteria",
    },
    {
      label: "Server Room",
      key: "Server Room",
    },
    {
      label: "Window Coverings",
      key: "Window Coverings",
    },
    {
      label: "Support Staff",
      key: "Support Staff",
    },
    {
      label: "Managed Reception",
      key: "Managed Reception",
    },
    {
      label: "Prime Locations",
      key: "Prime Locations",
    },
    {
      label: "WIFI",
      key: "WIFI",
    },
    {
      label: "Drinking Water",
      key: "Drinking Water",
    },
    {
      label: "Cubicle",
      key: "Cubicle",
    },
    {
      label: "Conference Room",
      key: "Conference Room",
    },
    {
      label: "Beverages",
      key: "Beverages",
    },
    {
      label: "CCTV Surveillance",
      key: "CCTV Surveillance",
    },
    {
      label: "Car Parking",
      key: "Car Parking",
    },
    {
      label: "Bike Parking",
      key: "Bike Parking",
    },
    {
      label: "Meeting Room",
      key: "Meeting Room",
    },
    {
      label: "Air Conditioning",
      key: "Air Conditioning",
    },
    {
      label: "Gym",
      key: "Gym",
    },
    {
      label: "Parking",
      key: "Parking",
    },
    {
      label: "Swimming pools",
      key: "Swimming pools",
    },
    {
      label: "Spa",
      key: "Spa",
    },
    {
      label: "Billiards Table",
      key: "Billiards Table",
    },
    {
      label: "Kid's Play Area",
      key: "Kid's Play Area",
    },
    {
      label: "CCTV",
      key: "CCTV",
    },
    {
      label: "Clubhouse",
      key: "Clubhouse",
    },
    {
      label: "Social area or rooftop garden",
      key: "Social area or rooftop garden",
    },
    {
      label: "Balcony",
      key: "Balcony",
    },
    {
      label: "Smart home",
      key: "Smart home",
    },
    {
      label: "Security systems and intercom",
      key: "Security systems and intercom",
    },
    {
      label: "Bathtubs",
      key: "Bathtubs",
    },
    {
      label: "Community Garden",
      key: "Community Garden",
    },
    {
      label: "Games Room",
      key: "Games Room",
    },
    {
      label: "Snooze Room",
      key: "Snooze Room",
    },
    {
      label: "EV Charger",
      key: "EV Charger",
    },
    {
      label: "Pet Friendly",
      key: "Pet Friendly",
    },
    {
      label: "Green Building/Sustainability",
      key: "Green Building/Sustainability",
    },
    {
      label: "Building Cafeteria",
      key: "Building Cafeteria",
    },
    {
      label: "Valet",
      key: "Valet",
    },
    {
      label: "Retail",
      key: "Retail",
    },
    {
      label: "Cafe",
      key: "Cafe",
    },
    {
      label: "Natural Lighting",
      key: "Natural Lighting",
    },
    {
      label: "Garden",
      key: "Garden",
    },
    {
      label: "Playground",
      key: "Playground",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getACTypeListingEle = () => {
  let data = [
    {
      label: "Chiller",
      key: "Chiller",
    },
    {
      label: "VRV",
      key: "VRV",
    },
    {
      label: "Split",
      key: "Split",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getCommonCafeteriaListingEle = () => {
  let data = [
    {
      label: "Yes",
      key: "Yes",
    },
    {
      label: "No",
      key: "No",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getPowerBackupListingEle = () => {
  let data = [
    {
      label: "100%",
      key: "100%",
    },
    {
      label: "Common Areas",
      key: "Common Areas",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getBuildingStatusListingEle = () => {
  let data = [
    {
      label: "Ready",
      key: "Ready",
    },
    {
      label: "Under Construction",
      key: "Under Construction",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

const getBuildingTypeListingEle = () => {
  let data = [
    {
      label: "Residential",
      key: "Residential",
    },
    {
      label: "Commercial",
      key: "Commercial",
    },
  ];
  return (
    <>
      {data?.map((item) => (
        <Select.Option value={item?.label} key={item?.key}>
          {item?.label}
        </Select.Option>
      ))}
    </>
  );
};

function PropertyDetailsCard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showCreateTxnBtn, setShowCreateTxnBtn] = useState(false);
  const [manualInputTCA, setManualInputTCA] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isProfilePic, setIsProfilePic] = useState(false);
  const [deletedFileList, setDeletedFileList] = useState([]);
  const {
    isBuildingInfoEditable,
    loading,
    propertyDetails,
    stateMaster,
    cityMaster,
    microMarketMaster,
    localityMaster,
    propertyId,
  } = useSelector((store) => store.propertyDetails);

  useEffect(() => {
    if (id) {
      dispatch(setPropertyId(id));
      dispatch(setBuildingInfoEditable(false));
      dispatch(getPropertyDetailsData(id));
    } else dispatch(setBuildingInfoEditable(true));

    dispatch(getAllDistinctStateMaster());
  }, []);

  useEffect(() => {
    if (!propertyId) return;
    dispatch(setBuildingInfoEditable(false));
    dispatch(getPropertyDetailsData(propertyId));
  }, [propertyId]);

  useEffect(() => {
    let propertyImages = [];
    if (propertyDetails?.propertyImages && propertyDetails?.profileImage) {
      propertyImages = [
        ...propertyDetails?.propertyImages,
        propertyDetails?.profileImage,
      ];
    } else if (
      propertyDetails?.propertyImages &&
      !propertyDetails?.profileImage
    ) {
      propertyImages = [...propertyDetails?.propertyImages];
    } else if (
      !propertyDetails?.propertyImages &&
      propertyDetails?.profileImage
    ) {
      propertyImages = [propertyDetails?.profileImage];
    }

    setFileList(propertyImages);

    if (propertyDetails?.state) {
      dispatch(getAllDistinctCityByState(propertyDetails?.state));
    }
    if (propertyDetails?.city) {
      dispatch(getAllDistinctMicroMarketByCity(propertyDetails?.city));
    }
    if (propertyDetails?.microMarket) {
      dispatch(
        getAllDistinctLocalityByCityAndMicromarket(propertyDetails?.microMarket)
      );
    }
  }, [
    propertyDetails?.state,
    propertyDetails?.city,
    propertyDetails?.microMarket,
    propertyDetails?.propertyImages,
    propertyDetails?.profileImage,
  ]);
  useEffect(() => {
    if (
      propertyDetails?.propertyImages &&
      propertyDetails?.propertyImages?.length > 0
    ) {
      setSelectedImage(
        propertyDetails?.propertyImages?.filter(
          (item) => item.isProfile === true
        )[0]
      );
      const profilePicIndex = propertyDetails?.propertyImages?.findIndex(
        (item) => item.isProfile === true
      );
      if (profilePicIndex !== -1) setIsProfilePic(true);
    }
  }, [propertyDetails?.propertyImages]);
  useEffect(() => {
    if (microMarketMaster.length === 0)
      dispatch(getAllDistinctLocalityByCityAndMicromarket());
  }, [microMarketMaster]);

  const handleImageClick = (file) => {
    if (isBuildingInfoEditable) {
      if (selectedImage === file) {
        setSelectedImage(null); // Deselect the image if it's already selected
        setIsProfilePic(false); // Deselect the profile picture checkbox
      } else {
        setSelectedImage(file); // Select the new image
        setIsProfilePic(false); // Deselect the profile picture checkbox
      }
    }
  };
  const handleDeleteImage = (fileToDelete) => {
    if (isBuildingInfoEditable) {
      // Find the index of the file to delete based on a unique identifier (e.g., id or another unique property)
      const indexToDelete = fileList.findIndex(
        (file) => file.uid === fileToDelete.uid
      );

      const deletedFiles = [...deletedFileList, fileList[indexToDelete]?.url];

      setDeletedFileList(deletedFiles);

      if (indexToDelete !== -1) {
        // Create a new array without the deleted file
        const newFileList = fileList.filter(
          (_, index) => index !== indexToDelete
        );

        // Update state with the new file list
        setFileList(newFileList);
        if (
          (newFileList && newFileList?.length === 0) ||
          selectedImage?.uid === fileToDelete?.uid
        )
          setSelectedImage(null);

        // Dispatch an action to update Redux with the new file list
        dispatch(
          setBuildingsInfo({
            name: "propertyImages",
            value: newFileList,
          })
        );
      }
    }
  };

  const handleProfilePicChange = (e) => {
    setIsProfilePic(e.target.checked);
  };

  const props = {
    onRemove: handleDeleteImage,
    beforeUpload: (file) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isAllowed = allowedTypes.includes(file.type);
      if (!isAllowed) {
        message.error("You can only upload JPG, JPEG, or PNG files!");
        return false;
      }
      const uuid = uuidv4(); // Generate UUID for the file
      file.uid = uuid; // Assign UUID as unique identifier for Ant Design Upload
      file.name = uuid; // Append UUID to the file name
      setFileList([...fileList, file]);
      return file;
    },
    onChange: (info) => {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const isAllowed = allowedTypes.includes(info.file.type);
      if (!isAllowed) return;
      const currFile = info.fileList.filter(
        (file) => file.uid === info.file.uid
      );
      dispatch(
        setBuildingsInfo({
          name: "propertyImages",
          value: [...(propertyDetails?.propertyImages || []), currFile[0]],
        })
      );
    },
  };

  const getStateListingEle = () => {
    if (!stateMaster) return [];
    let data = [...stateMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getCityListingEle = () => {
    if (!cityMaster) return [];
    let data = [...cityMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getMicromarketListingEle = () => {
    if (!microMarketMaster) return [];
    let data = [...microMarketMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getLocalityListingEle = () => {
    if (!localityMaster) return [];
    let data = [...localityMaster];
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const getBuildingClassificationListingEle = () => {
    let data = [];
    if (propertyDetails?.buildingType === "Residential") {
      data = [];
    } else if (propertyDetails?.buildingType === "Commercial") {
      data = [
        {
          label: "Malls",
          key: "Malls",
        },
        {
          label: "Offices",
          key: "Offices",
        },
        {
          label: "It parks",
          key: "It parks",
        },
        {
          label: "Retail",
          key: "Retail",
        },
      ];
    }
    return (
      <>
        {data?.map((item) => (
          <Select.Option value={item?.label} key={item?.key}>
            {item?.label}
          </Select.Option>
        ))}
      </>
    );
  };

  const buildingsInfoFormElementsColForUpdate = [
    {
      type: "input",
      label: "Property Name",
      required: true,
      name: "buildingName",
      placeholder: "Property Name",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingName,
    },
    {
      type: "input",
      label: "Total Chargeable Area",
      name: "totalChargeableArea",
      placeholder: "Total Chargeable Area",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.totalChargeableArea,
    },
    {
      type: "input",
      label: "Property Structure",
      name: "buildingStructure",
      placeholder: "Property Structure",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStructure,
    },
    {
      type: "input",
      label: "Total No. of Transactions",
      name: "numberOfTransactions",
      placeholder: "Total No. of Transactions",
      disabled: true,
      value: propertyDetails?.numberOfTransactions,
    },
    {
      type: "input",
      label: "Total Transaction Amount",
      name: "totalTransactionAmount",
      placeholder: "Total Transaction Amount",
      disabled: true,
      value: propertyDetails?.totalTransactionAmount,
    },
    {
      type: "dropdown",
      mode: "multiple",
      label: "Amenities",
      name: "amenities",
      placeholder: "Select Amenities",
      children: getAmenitiesListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.amenities,
    },
    {
      type: "inputNumber",
      label: "Parking Information of Two Wheeler",
      name: "parkingInformationOfTwoWheeler",
      placeholder: "Two Wheeler",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.parkingInformationOfTwoWheeler,
    },
    {
      type: "inputNumber",
      label: "Parking Information of Four Wheeler",
      name: "parkingInformationOfFourWheeler",
      placeholder: "Four Wheeler Parking Info",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.parkingInformationOfFourWheeler,
    },
    {
      type: "input",
      label: "Developer",
      name: "developer",
      placeholder: "Developer",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.developer,
    },
    {
      type: "inputNumber",
      label: "Efficiency (%)",
      name: "efficiency",
      placeholder: "Efficiency (%)",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.efficiency,
    },
    {
      type: "input",
      label: "Loading (%)",
      name: "loadingPercent",
      placeholder: "Loading (%)",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.loadingPercent,
    },
    {
      type: "input",
      label: "Avg CAM Chargeable",
      name: "avgCAMChargeable",
      placeholder: "Avg CAM Chargeable",
      disabled: true,
      value: propertyDetails?.avgCAMChargeable,
    },
    {
      type: "input",
      label: "Avg CAM Carpet",
      name: "avgCAMCarpet",
      placeholder: "Avg CAM Carpet",
      disabled: true,
      value: propertyDetails?.avgCAMCarpet,
    },
    {
      type: "input",
      label: "Avg FloorPlate Carpet",
      name: "avgFloorPlateCarpet",
      placeholder: "Avg FloorPlate Carpet",
      disabled: true,
      value: propertyDetails?.avgFloorPlateCarpet,
    },
    {
      type: "input",
      label: "Avg FloorPlate Chargeable",
      name: "avgFloorPlateChargeable",
      placeholder: "Avg FloorPlate Chargeable",
      disabled: true,
      value: propertyDetails?.avgFloorPlateChargeable,
    },
    {
      type: "input",
      label: "Avg Rent Rate (INR/ Sqft)",
      name: "avgRentRate",
      placeholder: "Avg Rent Rate (INR/ Sqft)",
      disabled: true,
      value: propertyDetails?.avgRentRate,
    },
    {
      type: "input",
      label: "Avg Sale Rate (INR/Sqft)",
      name: "avgSaleRate",
      placeholder: "Avg Sale Rate (INR/Sqft)",
      disabled: true,
      value: propertyDetails?.avgSaleRate,
    },
    {
      type: "input",
      label: "Average CAM",
      name: "averageCAM",
      placeholder: "Average CAM",
      disabled: true,
      value: propertyDetails?.averageCAM,
    },
    {
      type: "dropdown",
      label: "Common Cafeteria",
      name: "commonCafeteria",
      placeholder: "Common Cafeteria",
      children: getCommonCafeteriaListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.commonCafeteria,
    },
    {
      type: "dropdown",
      label: "Power Backup",
      name: "powerBackup",
      placeholder: "Power Backup",
      children: getPowerBackupListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.powerBackup,
    },
    {
      type: "dropdown",
      label: "AC Type",
      name: "acType",
      placeholder: "Select AC Type",
      children: getACTypeListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.acType,
    },
    {
      type: "input",
      label: "Remarks",
      name: "remarks",
      placeholder: "Remarks",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.remarks,
    },
    {
      type: "dropdown",
      label: "Property Type",
      name: "buildingType",
      required: true,
      placeholder: "Select Property Type",
      children: getBuildingTypeListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingType,
    },
    {
      type: "dropdown",
      label: "Property Classification",
      name: "buildingClassification",
      placeholder: "Select Classification",
      children: getBuildingClassificationListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingClassification,
    },
    {
      type: "dropdown",
      label: "Property Status",
      name: "buildingStatus",
      placeholder: "Select Property Status",
      children: getBuildingStatusListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStatus,
    },
    {
      type: "input",
      label: "Property Stats",
      name: "buildingStats",
      placeholder: "Property Stats",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStats,
    },
    {
      type: "inputNumber",
      label: "Property Age",
      name: "buildingAge",
      placeholder: "Property Age",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingAge,
    },
    {
      type: "input",
      label: "Grade of Property",
      name: "gradeOfBuilding",
      placeholder: "Grade of Property",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.gradeOfBuilding,
    },
    {
      type: "inputNumber",
      label: "Total Unit",
      name: "totalUnit",
      placeholder: "Total Unit",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.totalUnit,
    },
    {
      type: "datepicker",
      label: "Proposed Availability Date",
      name: "proposedAvailabilityDate",
      placeholder: "DD-MM-YYYY",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.proposedAvailabilityDate
        ? dayjs(propertyDetails?.proposedAvailabilityDate)
        : null,
      defaultValue: propertyDetails?.proposedAvailabilityDate
        ? dayjs(propertyDetails?.proposedAvailabilityDate)
        : null,
    },
    {
      type: "textArea",
      label: "Property Description",
      name: "propertyDescription",
      placeholder: "Property Description",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.propertyDescription,
      width: "500px",
    },
    {
      type: "input",
      label: "Address",
      name: "address",
      placeholder: "Address",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.address,
    },
    {
      type: "input",
      label: "Street",
      name: "street",
      placeholder: "Street",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.street,
    },
    {
      type: "input",
      label: "Plot No.",
      name: "plotNo",
      placeholder: "Plot No.",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.plotNo,
    },
    {
      type: "input",
      label: "Block No.",
      name: "blockNo",
      placeholder: "Block No.",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.blockNo,
    },
    {
      type: "inputNumber",
      label: "Pincode",
      name: "pincode",
      placeholder: "Pincode",
      disabled: !isBuildingInfoEditable,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isPincode(value)) {
              return Promise.reject("Please input correct pincode!");
            }
            return Promise.resolve();
          },
        }),
      ],
      value: propertyDetails?.pincode,
    },
    {
      type: "dropdown",
      label: "State",
      required: true,
      name: "state",
      placeholder: "State",
      children: getStateListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.state,
    },
    {
      type: "dropdown",
      label: "City",
      required: true,
      name: "city",
      placeholder: "City",
      children: getCityListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.city,
    },
    {
      type: "dropdown",
      label: "Micromarket",
      name: "microMarket",
      placeholder: "Micromarket",
      children: getMicromarketListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.microMarket,
    },
    {
      type: "dropdown",
      label: "Locality",
      required: true,
      name: "locality",
      placeholder: "Locality",
      children: getLocalityListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.locality,
    },
    {
      type: "input",
      label: "Latitude",
      name: "latitude",
      placeholder: "Latitude",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.latitude,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isLatitude(value)) {
              return Promise.reject("Please input correct latitude!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "Longitude",
      name: "longitude",
      placeholder: "Longitude",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.longitude,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isLongitude(value)) {
              return Promise.reject("Please input correct longitude!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
  ];

  const representativeFormElementsCol = [
    {
      type: "input",
      label: "Name",
      name: "name",
      placeholder: "Name",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.name,
    },
    {
      type: "input",
      label: "Email",
      name: "email",
      placeholder: "Email",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.email,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isEmail(value)) {
              return Promise.reject("Please input correct Email!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "inputNumber",
      label: "Contact",
      name: "contact",
      placeholder: "Contact",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.contact,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isMobileNumber(value)) {
              return Promise.reject("Please input correct Contact!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "Aadhar No",
      name: "aadharNo",
      placeholder: "Aadhar No",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.aadharNo,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isAadhar(value)) {
              return Promise.reject("Please input correct Aadhar No.!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "PAN",
      name: "PAN",
      placeholder: "PAN",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.PAN,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isPAN(value)) {
              return Promise.reject("Please input correct PAN!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
  ];

  const buildingsInfoFormElementsColForSave = [
    {
      type: "input",
      label: "Property Name",
      required: true,
      name: "buildingName",
      placeholder: "Property Name",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingName,
    },
    {
      type: "input",
      label: "Total Chargeable Area",
      name: "totalChargeableArea",
      placeholder: "Total Chargeable Area",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.totalChargeableArea,
    },
    {
      type: "input",
      label: "Property Structure",
      name: "buildingStructure",
      placeholder: "Property Structure",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStructure,
    },
    {
      type: "dropdown",
      mode: "multiple",
      label: "Amenities",
      name: "amenities",
      placeholder: "Select Amenities",
      children: getAmenitiesListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.amenities,
    },
    {
      type: "inputNumber",
      label: "Parking Information of Two Wheeler",
      name: "parkingInformationOfTwoWheeler",
      placeholder: "Two Wheeler",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.parkingInformationOfTwoWheeler,
    },
    {
      type: "inputNumber",
      label: "Parking Information of Four Wheeler",
      name: "parkingInformationOfFourWheeler",
      placeholder: "Four Wheeler Parking Info",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.parkingInformationOfFourWheeler,
    },
    {
      type: "input",
      label: "Developer",
      name: "developer",
      placeholder: "Developer",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.developer,
    },
    {
      type: "inputNumber",
      label: "Efficiency (%)",
      name: "efficiency",
      placeholder: "Efficiency (%)",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.efficiency,
    },
    {
      type: "input",
      label: "Loading (%)",
      name: "loadingPercent",
      placeholder: "Loading (%)",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.loadingPercent,
    },
    {
      type: "dropdown",
      label: "Common Cafeteria",
      name: "commonCafeteria",
      placeholder: "Common Cafeteria",
      children: getCommonCafeteriaListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.commonCafeteria,
    },
    {
      type: "dropdown",
      label: "Power Backup",
      name: "powerBackup",
      placeholder: "Power Backup",
      children: getPowerBackupListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.powerBackup,
    },
    {
      type: "dropdown",
      label: "AC Type",
      name: "acType",
      placeholder: "Select AC Type",
      children: getACTypeListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.acType,
    },
    {
      type: "input",
      label: "Remarks",
      name: "remarks",
      placeholder: "Remarks",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.remarks,
    },
    {
      type: "dropdown",
      label: "Property Type",
      name: "buildingType",
      required: true,
      placeholder: "Select Property Type",
      children: getBuildingTypeListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingType,
    },
    {
      type: "dropdown",
      label: "Property Classification",
      name: "buildingClassification",
      placeholder: "Select Classification",
      children: getBuildingClassificationListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingClassification,
    },
    {
      type: "dropdown",
      label: "Property Status",
      name: "buildingStatus",
      placeholder: "Select Property Status",
      children: getBuildingStatusListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStatus,
    },
    {
      type: "input",
      label: "Property Stats",
      name: "buildingStats",
      placeholder: "Property Stats",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingStats,
    },
    {
      type: "inputNumber",
      label: "Property Age",
      name: "buildingAge",
      placeholder: "Property Age",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.buildingAge,
    },
    {
      type: "input",
      label: "Grade of Property",
      name: "gradeOfBuilding",
      placeholder: "Grade of Property",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.gradeOfBuilding,
    },
    {
      type: "inputNumber",
      label: "Total Unit",
      name: "totalUnit",
      placeholder: "Total Unit",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.totalUnit,
    },
    {
      type: "datepicker",
      label: "Proposed Availability Date",
      name: "proposedAvailabilityDate",
      placeholder: "YYYY-MM-DD",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.proposedAvailabilityDate
        ? dayjs(propertyDetails?.proposedAvailabilityDate)
        : null,
      defaultValue: propertyDetails?.proposedAvailabilityDate
        ? dayjs(propertyDetails?.proposedAvailabilityDate)
        : null,
    },
    {
      type: "textArea",
      label: "Property Description",
      name: "propertyDescription",
      placeholder: "Property Description",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.propertyDescription,
      width: "500px",
    },
    {
      type: "input",
      label: "Address",
      name: "address",
      placeholder: "Address",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.address,
    },
    {
      type: "input",
      label: "Street",
      name: "street",
      placeholder: "Street",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.street,
    },
    {
      type: "input",
      label: "Plot No.",
      name: "plotNo",
      placeholder: "Plot No.",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.plotNo,
    },
    {
      type: "input",
      label: "Block No.",
      name: "blockNo",
      placeholder: "Block No.",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.blockNo,
    },
    {
      type: "inputNumber",
      label: "Pincode",
      name: "pincode",
      placeholder: "Pincode",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.pincode,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isPincode(value)) {
              return Promise.reject("Please input correct pincode!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "dropdown",
      label: "State",
      required: true,
      name: "state",
      placeholder: "State",
      children: getStateListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.state,
    },
    {
      type: "dropdown",
      label: "City",
      required: true,
      name: "city",
      placeholder: "City",
      children: getCityListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.city,
    },
    {
      type: "dropdown",
      label: "Micromarket",
      name: "microMarket",
      placeholder: "Micromarket",
      children: getMicromarketListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.microMarket,
    },
    {
      type: "dropdown",
      label: "Locality",
      required: true,
      name: "locality",
      placeholder: "Locality",
      children: getLocalityListingEle(),
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.locality,
    },
    {
      type: "input",
      label: "Latitude",
      name: "latitude",
      placeholder: "Latitude",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.latitude,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isLatitude(value)) {
              return Promise.reject("Please input correct latitude!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    {
      type: "input",
      label: "Longitude",
      name: "longitude",
      placeholder: "Longitude",
      disabled: !isBuildingInfoEditable,
      value: propertyDetails?.longitude,
      rules: [
        () => ({
          validator(_, value) {
            if (value && !isLongitude(value)) {
              return Promise.reject("Please input correct longitude!");
            }
            return Promise.resolve();
          },
        }),
      ],
    },
  ];

  const getUpdateBuildingPayload = (propertyDetails) => {
    const formData = new FormData();
    if (
      propertyDetails?.propertyImages &&
      propertyDetails?.propertyImages?.length > 0
    ) {
      for (
        let index = 0;
        index < propertyDetails?.propertyImages.length;
        index++
      ) {
        if (propertyDetails?.propertyImages?.[index]?.originFileObj) {
          formData.append(
            "images",
            propertyDetails?.propertyImages?.[index]?.originFileObj
          );
        }
      }
    }

    let filteredDeletedFiles = deletedFileList.filter(Boolean);
    selectedImage &&
      isProfilePic &&
      formData.append("profilePicName", selectedImage?.name);
    deletedFileList &&
      formData.append("deletedFiles", JSON.stringify(filteredDeletedFiles));
    propertyDetails?.buildingName &&
      formData.append("buildingName", propertyDetails?.buildingName);
    propertyDetails?.parkingInformationOfTwoWheeler &&
      formData.append(
        "twoWheelerParking",
        propertyDetails?.parkingInformationOfTwoWheeler
      );
    propertyDetails?.efficiency &&
      formData.append("efficiency", propertyDetails?.efficiency);
    propertyDetails?.powerBackup &&
      formData.append("powerBackup", propertyDetails?.powerBackup);
    propertyDetails?.buildingType &&
      formData.append("buildingType", propertyDetails?.buildingType);
    propertyDetails?.buildingStats &&
      formData.append("buildingStats", propertyDetails?.buildingStats);
    propertyDetails?.propertyDescription &&
      formData.append(
        "propertyDescription",
        propertyDetails?.propertyDescription
      );
    propertyDetails?.locality &&
      formData.append("locality", propertyDetails?.locality);
    propertyDetails?.pincode &&
      formData.append("pincode", propertyDetails?.pincode);
    propertyDetails?.microMarket &&
      formData.append("micromarket", propertyDetails?.microMarket);
    propertyDetails?.totalChargeableArea &&
      formData.append(
        "totalChargeableArea",
        propertyDetails?.totalChargeableArea
      );
    formData.append("manualInputTCA", manualInputTCA);
    propertyDetails?.amenities &&
      formData.append("amenities", propertyDetails?.amenities);
    propertyDetails?.parkingInformationOfFourWheeler &&
      formData.append(
        "fourWheelerParking",
        propertyDetails?.parkingInformationOfFourWheeler
      );
    propertyDetails?.loadingPercent &&
      formData.append("loading", propertyDetails?.loadingPercent);
    propertyDetails?.acType &&
      formData.append("acType", propertyDetails?.acType);
    propertyDetails?.buildingClassification &&
      formData.append(
        "buildingClassification",
        propertyDetails?.buildingClassification
      );
    propertyDetails?.buildingAge &&
      formData.append("buildingAge", propertyDetails?.buildingAge);
    propertyDetails?.gradeOfBuilding &&
      formData.append("gradeOfBuilding", propertyDetails?.gradeOfBuilding);
    propertyDetails?.totalUnit &&
      formData.append("totalUnit", propertyDetails?.totalUnit);
    propertyDetails?.address &&
      formData.append("address", propertyDetails?.address);
    propertyDetails?.plotNo &&
      formData.append("plotNo", propertyDetails?.plotNo);
    propertyDetails?.city && formData.append("city", propertyDetails?.city);
    propertyDetails?.latitude &&
      formData.append("latitude", propertyDetails?.latitude);
    propertyDetails?.buildingStructure &&
      formData.append("buildingStructure", propertyDetails?.buildingStructure);
    propertyDetails?.developer &&
      formData.append("developer", propertyDetails?.developer);
    propertyDetails?.commonCafeteria &&
      formData.append("commonCafeteria", propertyDetails?.commonCafeteria);
    propertyDetails?.remarks &&
      formData.append("remarks", propertyDetails?.remarks);
    propertyDetails?.buildingStatus &&
      formData.append("buildingStatus", propertyDetails?.buildingStatus);
    propertyDetails?.proposedAvailabilityDate &&
      formData.append(
        "proposedAvailabilityDate",
        propertyDetails?.proposedAvailabilityDate
      );
    propertyDetails?.street &&
      formData.append("street", propertyDetails?.street);
    propertyDetails?.blockNo &&
      formData.append("blockNo", propertyDetails?.blockNo);
    propertyDetails?.state && formData.append("state", propertyDetails?.state);
    propertyDetails?.longitude &&
      formData.append("longitude", propertyDetails?.longitude);
    propertyDetails?.name && formData.append("name", propertyDetails?.name);
    propertyDetails?.email && formData.append("email", propertyDetails?.email);
    propertyDetails?.contact &&
      formData.append("contact", propertyDetails?.contact);
    propertyDetails?.aadharNo &&
      formData.append("aadharNo", propertyDetails?.aadharNo);
    propertyDetails?.PAN && formData.append("PAN", propertyDetails?.PAN);
    if (id) {
      propertyDetails?.numberOfTransactions &&
        formData.append(
          "numberOfTransactions",
          propertyDetails?.numberOfTransactions
        );
      propertyDetails?.avgSaleRate &&
        formData.append("avgSaleRate", propertyDetails?.avgSaleRate);
      propertyDetails?.totalTransactionAmount &&
        formData.append(
          "totalTransactionsAmount",
          propertyDetails?.totalTransactionAmount
        );
      propertyDetails?.averageCAM &&
        formData.append("averageCAM", propertyDetails?.averageCAM);
      propertyDetails?.avgRentRate &&
        formData.append("avgRentRate", propertyDetails?.avgRentRate);
    }
    return formData;
  };

  const savePropertyInfoErrorMessage = (error) => {
    messageApi.open({
      type: "warning",
      content: error || "Something went wrong",
    });
  };

  const savePropertyInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Property created successfully",
    });
  };

  const updatePropertyInfoSuccessMessage = () => {
    messageApi.open({
      type: "success",
      content: "Property updated successfully",
    });
  };

  const propertyInfoValidationMessage = () => {
    messageApi.open({
      type: "warning",
      content: "Please check field validation",
    });
  };

  const handleChangeBuildingsInfo = (element, e, dateVal) => {
    if (element?.name === "totalChargeableArea" && e) {
      setManualInputTCA(true);
    } else if (element?.name === "totalChargeableArea" && !e) {
      setManualInputTCA(false);
    }
    if (element?.type === "datepicker") {
      form.setFieldsValue({
        ...form,
        [element?.name]: dateVal
          ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute")
          : null,
      });
      dispatch(
        setBuildingsInfo({
          name: element?.name,
          value: dateVal ? dayjs(dateVal, "DD-MM-YYYY").add(5, "hour").add(30, "minute") : null,
        })
      );
    } else if (
      element?.type === "inputNumber" ||
      element?.type === "dropdown"
    ) {
      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      dispatch(
        setBuildingsInfo({
          name: element?.name,
          value: e,
        })
      );
      if (element?.name === "state") {
        form.setFieldsValue({
          ...form,
          city: null,
          microMarket: null,
          locality: null,
        });
        dispatch(
          setBuildingsInfo({
            name: "city",
            value: null,
          })
        );
        dispatch(
          setBuildingsInfo({
            name: "microMarket",
            value: null,
          })
        );
        dispatch(
          setBuildingsInfo({
            name: "locality",
            value: null,
          })
        );
      } else if (element?.name === "city") {
        form.setFieldsValue({
          ...form,
          microMarket: null,
          locality: null,
        });
        dispatch(
          setBuildingsInfo({
            name: "microMarket",
            value: null,
          })
        );
        dispatch(
          setBuildingsInfo({
            name: "locality",
            value: null,
          })
        );
      } else if (element?.name === "microMarket") {
        form.setFieldsValue({
          ...form,
          locality: null,
        });
        dispatch(
          setBuildingsInfo({
            name: "locality",
            value: null,
          })
        );
      }
    } else {
      form.setFieldsValue({
        ...form,
        [element?.name]: e.target.value,
      });
      dispatch(
        setBuildingsInfo({
          name: element?.name,
          value: e.target.value,
        })
      );
    }
    if (element?.name === "state") {
      dispatch(getAllDistinctCityByState());
    } else if (element?.name === "city") {
      dispatch(getAllDistinctMicroMarketByCity());
    } else if (element?.name === "microMarket") {
      dispatch(getAllDistinctLocalityByCityAndMicromarket());
    }
  };

  const handleChangeRepresentativeInfo = (element, e, dateVal) => {
    if (element?.type === "inputNumber" || element?.type === "dropdown") {
      form.setFieldsValue({
        ...form,
        [element?.name]: e,
      });
      dispatch(
        setBuildingsInfo({
          name: element?.name,
          value: e,
        })
      );
    } else {
      form.setFieldsValue({
        ...form,
        [element?.name]: e.target.value,
      });
      dispatch(
        setBuildingsInfo({
          name: element?.name,
          value: e.target.value,
        })
      );
    }
  };
  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          {contextHolder}
          <div className="w-100 h-16 bg-orange-400 flex align-center items-center p-4">
            <Header />
          </div>
          <div style={{ padding: "50px" }}>
            <Form
              form={form}
              name="basic"
              onFinish={async () => {
                if (isBuildingInfoEditable === true && id) {
                  dispatch(
                    updateBuildingsInfo(
                      getUpdateBuildingPayload(propertyDetails)
                    )
                  );
                  updatePropertyInfoSuccessMessage();
                  setTimeout(() => {
                    dispatch(setLoading(true));
                    navigate("/dashboard");
                    dispatch(setBuildingInfoEditable(false));
                    dispatch(setPropertyId(null));
                    dispatch(setPropertyInitialState());
                  }, 1000);
                } else if (isBuildingInfoEditable === true && !id) {
                  dispatch(
                    saveBuildingsInfo(getUpdateBuildingPayload(propertyDetails))
                  )
                  .unwrap()
                  .then((data) => {
                    if (data?.status === 201) {
                      savePropertyInfoSuccessMessage();
                      setTimeout(() => {
                        dispatch(setLoading(false));
                        dispatch(setBuildingInfoEditable(false));
                        setShowCreateTxnBtn(true);
                      }, 1000)
                    }else{
                      savePropertyInfoErrorMessage(data?.response?.data?.error);
                    }
                  }).catch((error) =>console.log("error", error))
                }
              }}
              onFinishFailed={() => {
                propertyInfoValidationMessage();
              }}
            >
              <div style={{
                display: "inline-flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
                padding: "10px",
                marginBottom: "20px",
                width: "100%",
                position: "sticky",
                top: 0,
                zIndex: 1000,
                backgroundColor: "white",
                boxShadow: "0 8px 8px -8px rgba(0, 0, 0, 0.5)",
              }}>
                <Title level={4}>Property Information</Title>
                <div>
                  {showCreateTxnBtn && (
                    <React.Fragment>
                      <Button
                        onClick={() => {
                          navigate("/transactionDetails");
                        }}
                      >
                        Add Transaction
                      </Button>
                      &nbsp;&nbsp;
                    </React.Fragment>
                  )}
                  {!showCreateTxnBtn && (
                    <Fragment>
                      {isBuildingInfoEditable === true && id && (
                        <Button htmlType="submit">Update Building</Button>
                      )}
                      {isBuildingInfoEditable === true && !id && (
                        <Button htmlType="submit">Save Building</Button>
                      )}
                      {/* {isBuildingInfoEditable === false && id && (
                        <VerifySwitch />
                      )} */}
                      &nbsp;&nbsp;
                      {isBuildingInfoEditable === false && (
                        <Button
                          onClick={() =>
                            dispatch(setBuildingInfoEditable(true))
                          }
                        >
                          Edit Building
                        </Button>
                      )}
                    </Fragment>
                  )}
                  &nbsp;&nbsp;
                  <Button
                    onClick={() => {
                      navigate("/dashboard");
                      dispatch(setBuildingInfoEditable(false));
                      dispatch(setPropertyId(null));
                      dispatch(setPropertyInitialState());
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
              <Divider style={{ backgroundColor: "black" }} />
              <Row
                span={12}
                style={{
                  justifyContent: "space-between",
                  marginRight: "150px",
                }}
              >
                <Col span={6}>
                  {id
                    ? buildingsInfoFormElementsColForUpdate.map(
                      (element, index) => {
                        if (index % 3 === 0) {
                          return (
                            <FormElement
                              key={index}
                              {...element}
                              onChange={(e, dateVal) => {
                                handleChangeBuildingsInfo(
                                  element,
                                  e,
                                  dateVal
                                );
                              }}
                            />
                          );
                        }
                      }
                    )
                    : buildingsInfoFormElementsColForSave.map(
                      (element, index) => {
                        if (index % 3 === 0) {
                          return (
                            <FormElement
                              key={index}
                              {...element}
                              onChange={(e, dateVal) => {
                                handleChangeBuildingsInfo(
                                  element,
                                  e,
                                  dateVal
                                );
                              }}
                            />
                          );
                        }
                      }
                    )}
                </Col>
                <Col span={6}>
                  {id
                    ? buildingsInfoFormElementsColForUpdate.map(
                      (element, index) => {
                        if (
                          [
                            1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37,
                            40,
                          ].includes(index)
                        ) {
                          return (
                            <FormElement
                              key={index}
                              {...element}
                              onChange={(e, dateVal) => {
                                handleChangeBuildingsInfo(
                                  element,
                                  e,
                                  dateVal
                                );
                              }}
                            />
                          );
                        }
                      }
                    )
                    : buildingsInfoFormElementsColForSave.map(
                      (element, index) => {
                        if (
                          [
                            1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37,
                            40,
                          ].includes(index)
                        ) {
                          return (
                            <FormElement
                              key={index}
                              {...element}
                              onChange={(e, dateVal) => {
                                handleChangeBuildingsInfo(
                                  element,
                                  e,
                                  dateVal
                                );
                              }}
                            />
                          );
                        }
                      }
                    )}
                </Col>
                <Col span={6}>
                  {id
                    ? buildingsInfoFormElementsColForUpdate.map(
                      (element, index) => {
                        if (
                          [
                            2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38,
                            41,
                          ].includes(index)
                        ) {
                          return (
                            <FormElement
                              key={index}
                              {...element}
                              onChange={(e, dateVal) => {
                                handleChangeBuildingsInfo(
                                  element,
                                  e,
                                  dateVal
                                );
                              }}
                            />
                          );
                        }
                      }
                    )
                    : buildingsInfoFormElementsColForSave.map(
                      (element, index) => {
                        if (
                          [
                            2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38,
                            41,
                          ].includes(index)
                        ) {
                          return (
                            <FormElement
                              key={index}
                              {...element}
                              onChange={(e, dateVal) => {
                                handleChangeBuildingsInfo(
                                  element,
                                  e,
                                  dateVal
                                );
                              }}
                            />
                          );
                        }
                      }
                    )}
                </Col>
              </Row>
              <br />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Title level={4}>Property Representative Detail</Title>
              </div>
              <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
              <Row
                span={12}
                style={{
                  justifyContent: "space-between",
                  marginRight: "150px",
                }}
              >
                <Col span={6}>
                  {representativeFormElementsCol.map((element, index) => {
                    if (index % 3 === 0) {
                      return (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeRepresentativeInfo(element, e, dateVal);
                          }}
                        />
                      );
                    }
                  })}
                </Col>
                <Col span={6}>
                  {representativeFormElementsCol.map((element, index) => {
                    if (
                      [
                        1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34, 37, 40,
                      ].includes(index)
                    ) {
                      return (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeRepresentativeInfo(element, e, dateVal);
                          }}
                        />
                      );
                    }
                  })}
                </Col>
                <Col span={6}>
                  {representativeFormElementsCol.map((element, index) => {
                    if (
                      [
                        2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35, 38, 41,
                      ].includes(index)
                    ) {
                      return (
                        <FormElement
                          key={index}
                          {...element}
                          onChange={(e, dateVal) => {
                            handleChangeRepresentativeInfo(element, e, dateVal);
                          }}
                        />
                      );
                    }
                  })}
                </Col>
              </Row>
            </Form>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title level={4}>Add Buildings Images</Title>
            </div>
            <Title level={5}>Note: Extensions allowed - jpg, png, jpeg</Title>
            <Divider style={{ backgroundColor: "black", marginTop: "0" }} />
            <Row span={12} style={{ columnGap: "10px", marginRight: "150px" }}>
              {isBuildingInfoEditable && (
                <Upload
                  {...props}
                  fileList={
                    isBuildingInfoEditable
                      ? propertyDetails?.propertyImages
                      : fileList
                  }
                  disabled={!isBuildingInfoEditable}
                  preview={false}
                  maxCount={14}
                  showUploadList={false}
                  multiple={false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    loading={uploading}
                    disabled={!isBuildingInfoEditable}
                  >
                    Select File
                  </Button>
                </Upload>
              )}
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {propertyDetails?.propertyImages?.length > 0 ? (
                    propertyDetails?.propertyImages.map((file, index) => (
                      <div
                        key={index}
                        style={{
                          position: "relative",
                          margin: "0px 10px 0px 10px",
                        }}
                      >
                        {isBuildingInfoEditable && (
                          <Checkbox
                            style={{
                              position: "absolute",
                              top: 3,
                              left: 5,
                              zIndex: 1,
                            }}
                            onChange={(e) => handleImageClick(file)}
                            checked={selectedImage === file}
                            disabled={!isBuildingInfoEditable}
                          />
                        )}
                        {isBuildingInfoEditable && (
                          <CloseOutlined
                            style={{
                              color: "black",
                              position: "absolute",
                              top: 3,
                              right: 5,
                              zIndex: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteImage(file)}
                          />
                        )}
                        {(file.originFileObj || file.url) && (
                          <Image
                            width={200}
                            height={150}
                            preview={false}
                            src={
                              file.url ||
                              URL.createObjectURL(file.originFileObj)
                            }
                            alt={`Uploaded image ${index + 1}`}
                            style={
                              file?.isProfile
                                ? {
                                  border: "4px solid orange",
                                  padding: "2px",
                                  borderRadius: "8px",
                                }
                                : { borderRadius: "8px" }
                            }
                          />
                        )}
                      </div>
                    ))
                  ) : (
                    <>
                      {!isBuildingInfoEditable && <h2>No Images to display</h2>}
                    </>
                  )}
                </div>

                {isBuildingInfoEditable && (
                  <div
                    style={{
                      marginLeft: 20,
                      flex: "1",
                      margin: "0px 10px 0px 10px",
                    }}
                  >
                    {selectedImage ? (
                      <>
                        <Row>
                          {(selectedImage.url ||
                            selectedImage.originFileObj) && (
                              <Image
                                width={320}
                                height={280}
                                src={
                                  selectedImage.url ||
                                  (selectedImage.originFileObj &&
                                    URL.createObjectURL(
                                      selectedImage.originFileObj
                                    ))
                                }
                                alt="Selected Image Preview"
                                style={{ borderRadius: "8px" }}
                              />
                            )}
                        </Row>
                        <Row>
                          <Checkbox
                            style={{ marginTop: 10 }}
                            onChange={handleProfilePicChange}
                            checked={isProfilePic}
                          >
                            Set this image as your profile picture
                          </Checkbox>
                        </Row>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "20px",
                          border: "1px dashed #d9d9d9",
                          borderRadius: "8px",
                          width: "320px",
                          height: "280px",
                        }}
                      >
                        Your image preview will display here
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Row>
          </div>
        </>
      )}
    </>
  );
}

export default PropertyDetailsCard;
