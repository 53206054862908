import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getConnectList = async (body) => {
  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-all-connects?pageSize=${body.pageSize}&page=${body.page}`
    )
  );
  if (err) return err;
  return data;
};

const getFilteredConnect = async (body) => {
  const postBody = {
    personName: body?.personName || null,
    personNameFilter: body?.personNameFilter,
    currentCompany: body?.currentCompany || null,
    currentCompanyFilter: body?.currentCompanyFilter,
    emailAddress: body?.emailAddress || null,
    contactNumber: body?.contactNumber || null,
    contactNumberFilter: body?.contactNumberFilter,
    startDate: body?.startDate || null,
    endDate: body?.endDate || null,
    designation: body?.designation || null,
    designationFilter: body?.designationFilter,
    currentIndustry: body?.currentIndustry || null,
    currentIndustryFilter: body?.currentIndustryFilter,
    experience: +body?.experience || null,
    experienceFilter: body?.experienceFilter,
    keySkills: body?.keySkills || null,
    keySkillsFilter: body?.keySkillsFilter,
  };
  const [data, err] = await asyncHandler(
    client.post(
      `/api/connects-advanced-search?pageSize=${body?.pageSize}&page=${body.currentPage}`,
      postBody
    )
  );
  if (err) return err;
  return data;
};

const getConnectDetailsData = async (connectId) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-connect-detail/${connectId}`)
  );
  if (err) return err;
  return data;
};

const updateConnectDetailsData = async (param, connectId) => {
  try {
    const response = await client.patch(
      `/api/update-connect-detail/${connectId}`,
      param,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

const saveConnectDetailsData = async (param) => {
  try {
    const [data, err] = await asyncHandler(client.post(`/api/create-connect`, param, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }));
    return data || err;
  } catch (err) {
    console.error(err);
  }
};

const deleteConnect = async (connectId) => {
  try {
    const response = await client.delete(`/api/delete-connect/${connectId}`);
    return response;
  } catch (err) {
    console.error(err);
  }
};

const getAllDistinctStateMaster = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-connect-distinct-state-master`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctCityByState = async (state) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-connect-distinct-city-master/${state}`)
  );
  if (err) return err;
  return data;
};

const getAllDistinctLocalityByCity = async (city) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-connect-distinct-locality-master/${city}`)
  );
  if (err) return err;
  return data;
};

const getCompanyWithSearchableText = async (companyText) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-companies-search/${companyText}`)
  );
  if (err) return err;
  return data;
};

const validateConnectEmail = async (email) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/validate-connect-email/${email}`)
  );
  if (err) return err;
  return data;
};

const validateConnectContactNumber = async (contactNumber) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/validate-connect-contact/${contactNumber}`)
  );
  if (err) return err;
  return data;
};

export {
  getConnectList,
  getFilteredConnect,
  getConnectDetailsData,
  updateConnectDetailsData,
  saveConnectDetailsData,
  deleteConnect,
  getAllDistinctStateMaster,
  getAllDistinctCityByState,
  getAllDistinctLocalityByCity,
  getCompanyWithSearchableText,
  validateConnectEmail,
  validateConnectContactNumber,
};
