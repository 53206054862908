import dayjs from "dayjs";

export const FormatCompanyDetails = (data) => {
  const companyImageData = [];
  for (
    let index = 0;
    index < data?.companyDetails?.companyImages?.length;
    index++
  ) {
    const element = {
      uid: index,
      name: data?.companyDetails?.companyImages?.[index]?.split(".com/")[1],
      status: "done",
      url: data?.companyDetails?.companyImages?.[index],
    };
    companyImageData.push(element);
  }
  const companyLogoData = [];
  if (data?.companyDetails?.companyLogo) {
    const element = {
      uid: companyImageData?.length,
      name: data?.companyDetails?.companyLogo?.split(".com/")[1],
      status: "done",
      url: data?.companyDetails?.companyLogo,
    };
    companyLogoData.push(element);
  }
  return {
    ...data?.companyDetails,
    dateOfIncorporation: data?.companyDetails?.dateOfIncorporation
      ? dayjs(data?.companyDetails?.dateOfIncorporation)
      : "",
    companyImages: companyImageData,
    companyLogo: companyLogoData,
  };
};
