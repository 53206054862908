import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getReportList = async (body) => {
  const { pageSize, page, start, end } = body;
  const startDate = start || "";
  const endDate = end || "";

  const [data, err] = await asyncHandler(
    client.get(
      `/api/get-all-user-action-reports?pageSize=${pageSize}&page=${page}&start=${startDate}&end=${endDate}`
    )
  );
  if (err) return err;
  return data;
};

const getReportDetails = async (body) => {
  const {
    pageSize,
    page,
    userId,
    category,
    startDate = "",
    endDate = "",
  } = body;

  const [data, err] = await asyncHandler(
    client.get(
      `/api/report-details/${userId}/${category}?pageSize=${pageSize}&page=${page}&start=${startDate}&end=${endDate}`
    )
  );
  if (err) return err;
  return data;
};

export { getReportList, getReportDetails };
