import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getDashboardCounts = async ({ state = '', city = '', locality = '' }) => {
  // Handle default values for parameters
  const [response, err] = await asyncHandler(
      client.get(`/api/get-dashboard-counts`, {
        params: { state, city, locality }
      })
  );
  if (err) return Promise.reject(err);
  return response.data;
};

const getUserDetails = async () => {
  const [response, err] = await asyncHandler(
    client.get(`/api/get-top-perfome-users`)
  );
  if (err) return Promise.reject(err);
  return response.data;
};

export { getDashboardCounts, getUserDetails };
