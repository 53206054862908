export const FormatConnectDetails = (data) => {
  return {
    personName: data?.connectDetails?.personName,
    emailAddress: data?.connectDetails?.emailAddress,
    contactNumber: data?.connectDetails?.contactNumber,
    pan: data?.connectDetails?.pan,
    aadharNumber: data?.connectDetails?.aadharNumber,
    linkedinURL: data?.connectDetails?.linkedinURL,
    gender: data?.connectDetails?.gender,
    nationality: data?.connectDetails?.nationality,
    userType: data?.connectDetails?.userType,
    currentCompany: data?.connectDetails?.currentCompany,
    designation: data?.connectDetails?.designation,
    workEmail: data?.connectDetails?.workEmail,
    workContactNumber: data?.connectDetails?.workContactNumber,
    previousCompanyName: data?.connectDetails?.previousCompanyName,
    previousCompanyDesignation:
      data?.connectDetails?.previousCompanyDesignation,
    yearsOfExperience: data?.connectDetails?.experience,
    expertise: data?.connectDetails?.expertise,
    preferences: data?.connectDetails?.preferences,
    services: data?.connectDetails?.services,
    streams: data?.connectDetails?.streams,
    keySkills: data?.connectDetails?.keySkills,
    occupation: data?.connectDetails?.occupation,
    otherOccupations: data?.connectDetails?.otherOccupations,
    address: data?.connectDetails?.complete_address,
    flatNo: data?.connectDetails?.flatNo,
    floorNo: data?.connectDetails?.floorNo,
    street: data?.connectDetails?.street,
    state: data?.connectDetails?.state,
    city: data?.connectDetails?.city,
    locality: data?.connectDetails?.locality,
    pincode: data?.connectDetails?.pincode,
  };
};
