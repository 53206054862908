import React from "react";
import { useNavigate } from "react-router-dom";
import {
  setAccessToken,
  setPassword,
  setEmail,
  setUserInitialState,
} from "../../feature/slice/UserSlice";
import { setPropertyInitialState } from "../../feature/slice/PropertyDetailsSlice";
import logo from "../../assets/logo.svg";
import { useDispatch } from "react-redux";
import { setTransactionInitialState } from "../../feature/slice/TransactionDetailsSlice";
import { setSelectedNav } from "../../feature/slice/FiltersSlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <img src={logo} alt="logo" width="150px" className="header__logo-img" />
      <span
        style={{ cursor: "pointer" }}
        onClick={() => {
          dispatch(setEmail(null));
          dispatch(setPassword(null));
          dispatch(setAccessToken(null));
          navigate("/", { replace: true });
          dispatch(setPropertyInitialState());
          dispatch(setUserInitialState());
          dispatch(setTransactionInitialState());
          dispatch(setSelectedNav("Property"));
        }}
      >
        Logout
      </span>
    </div>
  );
};

export default Header;
