import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import { getUsersData as _getUsersData } from "../services/UserAPI";
import { get } from "lodash";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  user: {
    email: null,
    password: null,
  },
  accessToken: null,
};

const userSlice = createSliceWithThunks({
  name: "user",
  initialState,
  reducers: (create) => ({
    setEmail: (state, action) => {
      state.user.email = action?.payload;
    },
    setPassword: (state, action) => {
      state.user.password = action?.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action?.payload;
    },
    initLogin: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getUsersData(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.accessToken = null;
        },
        rejected: (state, action) => {
          state.loading = false;
          state.accessToken = null;
        },
        fulfilled: (state, action) => {
          state.accessToken = action?.payload?.data?.token;
          state.loading = false;
        },
      }
    ),
    setUserInitialState: (state, action) => {
      state = initialState;
    },
  }),
});

export const {
  setEmail,
  setPassword,
  setAccessToken,
  initLogin,
  setUserInitialState,
} = userSlice.actions;
// export const { setSelectedKey } = contactBookSlice.actions;
export default userSlice.reducer;
