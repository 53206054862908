import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getTransactionList = async (body) => {
  const endpoint = body?.propertyId
    ? `/api/get-property-transactions/${body.propertyId}?pageSize=${body.pageSize}&page=${body.page || 1}`
    : `/api/get-all-transaction?pageSize=${body.pageSize}&page=${body.page || 1}`;
  try {
    const response = await client.get(endpoint);
    const data = response.data;
    return { data, error: null };
  } catch (err) {
    return { error: err };
  }
};

const getAllTransactions = async (body) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-transaction?pageSize=${body.pageSize}&page=${body.page}`)
  );
  if (err) return err;
  return data;
};

const bulkUploadTransactions = async (file) => {
  const formData = new FormData();
  formData.append("file", file);


  try {
    const response = await client.post("/api/bulk-upload/transactions",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return [response.data, null];
  } catch (error) {
    console.error("Error uploading file:", error.response ? error.response.data : error.message);
    return [null, error];
  }
};

const getFilteredTransaction = async (body) => {
  const postBody = {
    buildingName: body.buildingName ?? null,
    buildingNameFilter: body.buildingNameFilter ?? 'contains',
    documentNo: body.documentNo ?? null,
    documentNoFilter: body.documentNoFilter ?? 'equals',
    documentName: body.documentName ?? [],
    registrationStartDate: body.registrationStartDate ?? null,
    registrationEndDate: body.registrationEndDate ?? null,
    locality: body.locality ?? [],
    proposedAvailabilityStartDate: body.proposedAvailabilityStartDate ?? null,
    proposedAvailabilityEndDate: body.proposedAvailabilityEndDate ?? null,
    unitNo: body.unitNo ?? null,
    unitNoFilter: body.unitNoFilter ?? null,
    floorNo: body.floorNo ?? null,
    floorNoFilter: body.floorNoFilter ?? null,
    saleRate: body.saleRate ?? null,
    saleRateFilter: body.saleRateFilter ?? 'equals',
    rentRate: body.rentRate ?? null,
    rentRateFilter: body.rentRateFilter ?? 'equals',
    chargeableSquareFeet: body.chargeableSquareFeet ?? null,
    chargeableSquareFeetFilter: body.chargeableSquareFeetFilter ?? 'equals',
    microMarket: body.microMarket ?? [],
    cam: body.cam ?? null,
    camFilter: body.camFilter ?? 'equals',
    createdAtStartDate: body.createdAtStartDate ?? null,
    createdAtEndDate: body.createdAtEndDate ?? null,
    propertyId: body?.id,
  };

  const [data, err] = await asyncHandler(
    client.post(
      `/api/advanced-search-transactions?pageSize=${body?.pageSize}&page=${body.currentPage}`,
      postBody
    )
  );

  if (err) {
    console.error('Error in API call:', err);
    return err;
  }

  console.log('Received data:', data);
  return data;
};

const deleteTransaction = async (transactionId) => {
  const [data, err] = await asyncHandler(client.delete(`/api/delete-transaction/${transactionId}`));
  if (err) return err;
  return data;
};


const getTransactionDetailsData = async (transactionId, propertyId) => {
  const endpoint = propertyId
    ? `/api/get-property-transactions-details/transaction/${transactionId}/property/${propertyId}`
    : `/api/get-transaction-detail/${transactionId}`;

  const [data, err] = await asyncHandler(client.get(endpoint));

  if (err) return err;
  return data;
};

const getCompanyWithSearchableText = async (companyText) => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-companies-search/${companyText}`)
  );
  if (err) return err;
  return data;
};

const updateTransactionDetailsData = async (
  param,
  transactionId,
  moreTransactonId
) => {
  try {
    const response = await client.patch(
      `/api/update-transaction-detail/transaction/${transactionId}/moreTransaction/${moreTransactonId}`,
      param
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

const saveTransactionDetailsData = async (data) => {
  try {
    const [res, err] = await asyncHandler(client.post(
      `/api/create-transaction/property/${data.propertyId}`,
      data
    ));
    return res || err;
  } catch (err) {
    console.error(err);
  }
};

const getAllDocumentMaster = async () => {
  const [data, err] = await asyncHandler(
    client.get(`/api/get-all-document-master`)
  );
  if (err) return err;
  return data;
};

export {
  getTransactionList,
  getAllTransactions,
  getTransactionDetailsData,
  getFilteredTransaction,
  updateTransactionDetailsData,
  getCompanyWithSearchableText,
  saveTransactionDetailsData,
  getAllDocumentMaster,
  bulkUploadTransactions,
  deleteTransaction,
};
