export const isMobileNumber = (input) => {
  // Regular expression to match mobile numbers (assuming international format)
  const mobileNumberRegex = /^[0-9]{10}$/;
  return mobileNumberRegex.test(input);
};

export const isEmail = (input) => {
  // Regular expression to match email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input);
};

export const isPincode = (input) => {
  const pincodeRegex = /^(\d{4}|\d{6})$/;
  return pincodeRegex.test(input);
};

export const isGST = (input) => {
  const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  return gstRegex.test(input);
};

export const isLatitude = (input) => {
  const latRegex = /^[-]?((([0-8]?[0-9])(\.[0-9]+)?)|(90(\.0+)?))$/;
  return latRegex.test(input);
};

export const isUrl = (input) => {
  const urlRegex =
    /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|localhost)(:\d{1,5})?(\/[a-zA-Z0-9.-]*)*(\?[a-zA-Z0-9=&%_.-]*)?(#[a-zA-Z0-9!$&'()*+,;=._~-]*)?$/;
  return urlRegex.test(input);
};

export const isLongitude = (input) => {
  const longRegex =
    /^[-]?((((1[0-7][0-9])|([0-9]?[0-9])))(\.[0-9]+)?)|(180(\.0+)?)$/;
  return longRegex.test(input);
};

export const isAadhar = (input) => {
  const aadharRegex = /^[0-9]{12}$/;
  return aadharRegex.test(input);
};

export const isPAN = (input) => {
  const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  return panRegex.test(input);
};
