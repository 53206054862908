import { client } from "../../utils/apiClient";
import { asyncHandler } from "../../utils/asyncHandler";

const getUsersData = async (body) => {
  const [data, err] = await asyncHandler(
    client.post(`/auth/login`, body, {
      authorization: false,
    })
  );
  if (err) return err;
  return data;
};

export { getUsersData };
