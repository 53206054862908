import React from "react";
import { Layout, Menu } from "antd";
import { useSelector } from "react-redux";
import OrganizationsIcon from "../../assets/organizations.svg";
import DashboardIcon from "../../assets/dashboard.svg";
import PropertiesIcon from "../../assets/properties.svg";
import ConnectsIcon from "../../assets/connects.svg";
import TransactionsIcon from "../../assets/transactions.svg";
import ReportIcon from "../../assets/report.svg";


function getMenuItem(label, key, icon, children, type, disabled) {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
  };
}

function SideNav(props) {
  const { selectedNav } = useSelector((store) => store.filters);
  const { Sider } = Layout;
  const items = [
    getMenuItem(
      <h2
        onClick={() => {
          props.handleChangeSelectedNav("Dashboard");
        }}
      >
        Dashboard
      </h2>,
      "Dashboard",
      <img src={DashboardIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false,
    ),
    getMenuItem(
      <h2
        onClick={() => {
          if (selectedNav !== "Property") {
            props.handleChangeSelectedNav("Property");
          }
        }}
      >
        Property
      </h2>,
      "Property",
      <img src={PropertiesIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false
    ),
    getMenuItem(
      <h2
        onClick={() => {
          if (selectedNav !== "Transactions") {
            props.handleChangeSelectedNav("Transactions");
          }
        }}
      >
        Transaction
      </h2>,
      "Transactions",
      <img src={TransactionsIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false
    ),
    getMenuItem(
      <h2
        onClick={() => {
          if (selectedNav !== "Company") {
            props.handleChangeSelectedNav("Company");
          }
        }}
      >
        Company
      </h2>,
      "Company",
      <img src={OrganizationsIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false
    ),
    getMenuItem(
      <h2
        onClick={() => {
          if (selectedNav !== "Connect") {
            props.handleChangeSelectedNav("Connect");
          }
        }}
      >
        Connect
      </h2>,
      "Connect",
      <img src={ConnectsIcon} style={{ width: "24px" }} alt="" />,
      null,
      null,
      false
    ),
    getMenuItem(
      <h2
        onClick={() => {
          if (selectedNav !== "Report") {
            props.handleChangeSelectedNav("Report");
          }
        }}
      >
        Report
      </h2>,
      "Report",
      <img src={ReportIcon
      } style={{ width: "24px" }} alt="" />,
      null,
      null,
      false
    ),
  ];
  return (
    <Layout>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        style={{ background: "#ffffff" }}
        width={200}
      >
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[props.selectedNav]}
          items={items}
          style={{
            height: "100%",
            marginTop: "1rem",
          }}
        />
      </Sider>
    </Layout>
  );
}

export default SideNav;
