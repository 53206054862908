import React, { useEffect } from "react";
import { Table, Typography, Pagination, Button, Spin } from "antd";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setPageSize,
  getTransactionList,
  setTransactionInitialState,
  setTransactionId,
} from "../../feature/slice/TransactionDetailsSlice";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import { setFilters } from "../../feature/slice/FiltersSlice";
import Header from "../../components/header/Header";
import { setPropertyId } from "../../feature/slice/PropertyDetailsSlice";

const TransactionListDetails = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, transactionsDetailsData, currentPage, pageSize } =
    useSelector((store) => store.transactionDetails);
  const { transactionsCnt } = useSelector((store) => store.propertyDetails);
  const { transactionFilters, localitiesData } = useSelector(
    (store) => store.filters
  );

  const columns = [
    {
      title: "Building Name",
      dataIndex: "buildingName",
      width: 250,
      editable: true,
      fixed: "left",
    },
    {
      title: "Document No",
      dataIndex: "documentNo",
      width: 200,
      editable: true,
      fixed: "left",
    },
    {
      title: "Document Name",
      dataIndex: "documentName",
      width: 250,
      editable: true,
      fixed: "left",
    },
    {
      title: "Registration Date",
      dataIndex: "registrationDate",
      width: 250,
      editable: true,
      render: (registrationDate) =>
        registrationDate ? dayjs(registrationDate).format("DD-MM-YYYY") : null,
    },
    {
      title: "Locality",
      dataIndex: "locality",
      width: 100,
      editable: true,
    },
    {
      title: "Proposed Availability Date",
      dataIndex: "proposedAvailabilityDate",
      width: 150,
      editable: true,
      render: (proposedAvailabilityDate) =>
        proposedAvailabilityDate
          ? dayjs(proposedAvailabilityDate).format("DD-MM-YYYY")
          : null,
    },
    {
      title: "Unit No.",
      dataIndex: "unitNo",
      width: 150,
      editable: true,
    },
    {
      title: "Floor No.",
      dataIndex: "floorNo",
      width: 100,
      editable: true,
    },
    {
      title: "Sale Rate",
      dataIndex: "saleRate",
      width: 100,
      editable: true,
    },
    {
      title: "Rent Rate",
      dataIndex: "rentRate",
      width: 150,
      editable: true,
    },
    {
      title: "Chargeable Area",
      dataIndex: "chargeableSquareFeet",
      width: 250,
      editable: true,
    },
    {
      title: "MicroMarket",
      dataIndex: "microMarket",
      width: 100,
      editable: true,
      render: (microMarket) =>
        !isEmpty(microMarket) ? microMarket.join(", ") : null,
    },
    {
      title: "Average CAM",
      dataIndex: "CAM",
      width: 100,
      editable: true,
    },
    {
      title: "Operation",
      dataIndex: "transactionId",
      width: 250,
      fixed: "right",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Link
              onClick={() => navigateTo(`/transactionDetails/${record?.transactionId}`)}
            >
              View
            </Typography.Link>
          </div>
        );
      },
    }
  ];

  useEffect(() => {
    dispatch(setPropertyId(null));
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(getTransactionList({ pageSize, page: currentPage, propertyId: id }));
    }
  }, [dispatch, currentPage, pageSize, id]);

  useEffect(() => {
    if (transactionsDetailsData) {
    }
  }, [transactionsDetailsData]);

  useEffect(() => {
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));

  }, [dispatch]);

  const handleResetTransactionSearch = () => {
    dispatch(setFilters({ name: "localities", value: [] }));
    dispatch(setFilters({ name: "buildingName", value: null }));
    dispatch(setFilters({ name: "documentNo", value: null }));
  };

  const handlePaginationChange = (page, size) => {
    dispatch(setCurrentPage(page));
    dispatch(setPageSize(size));

    if (id) {
      const localitiesDataForFilter = transactionFilters?.localities.map(index => localitiesData?.[index]?.title) || [];
      const body = {
        documentNumber: transactionFilters?.documentNo,
        buildingName: transactionFilters?.buildingName,
        localities: localitiesDataForFilter,
        currentPage: page,
        pageSize: size,
        propertyId: id,
      };
      dispatch(getTransactionList(body));
    }
  };

  const onShowSizeChange = (current, size) => {
    dispatch(setPageSize(size));
    dispatch(setCurrentPage(1));

    if (id) {
      const localitiesDataForFilter = transactionFilters?.localities.map(index => localitiesData?.[index]?.title) || [];
      const body = {
        documentNumber: transactionFilters?.documentNo,
        buildingName: transactionFilters?.buildingName,
        localities: localitiesDataForFilter,
        currentPage: 1,
        pageSize: size,
        propertyId: id,
      };
      dispatch(getTransactionList(body));
    }
  };

  return (
    <>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          <div className="w-100 h-16 bg-orange-400 flex align-center items-center p-4">
            <Header />
          </div>

          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "20px",
              }}
            >
              <div className="m-4 align-center property">
                <h1>Transactions List</h1>
              </div>
              <div className="list-options m-4">
                <Button
                  className="mr-4"
                  onClick={() => {
                    handleResetTransactionSearch();
                    navigateTo("/transactionDetails");
                    dispatch(setTransactionId(null));
                    dispatch(setTransactionInitialState());
                  }}
                >
                  Add Transaction
                </Button>
                <Button onClick={() => {
                  navigateTo("/dashboard");
                  dispatch(setPropertyId(null));
                }}>Back</Button>
              </div>
            </div>
            <Table
              dataSource={Array.isArray(transactionsDetailsData) ? transactionsDetailsData : []}
              columns={columns}
              rowKey="transactionId"
              pagination={false}
              locale={{ emptyText: 'No transactions found' }}
            />
            {transactionsDetailsData?.length > 0 && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={handlePaginationChange}
                total={transactionsCnt}
                style={{ marginLeft: "10px", marginTop: "10px" }}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default TransactionListDetails;
