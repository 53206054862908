import { client } from "./index";
import { nanoid } from "@reduxjs/toolkit";
import { persistor } from "../../feature/store/store";

const setUpInterceptor = (accessToken) => {
  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));
  const logout = () => {
    persistor.purge().then(() => {
      localStorage.clear();
      delay(5000);
      window.location.replace("/");
    });
  };
  // GET ACCESS TOKEN HERE FROM STATE

  client.interceptors.request.use(
    async (config) => {
      const corelationId = nanoid();
      config.headers["x-correlation-id"] = corelationId;

      if (config.authorization !== false) {
        if (accessToken) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
          config.headers["x-user-id"] = `${corelationId}-getUserIDFromStore`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );
};

export default setUpInterceptor;
