import React, { Fragment, useEffect, useState } from "react";
import { DatePicker, Button, Space, Collapse, message, Spin } from "antd";
import FiltersTree from "../filtersTree/FiltersTree";
import FilterIcon from "../../assets/filterIcon.png";
import CloseIcon from "../../assets/closeIcon.png";
import SearchInput from "../SearchInput/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocalities,
  setFilters,
  getCities,
} from "../../feature/slice/FiltersSlice";
import {
  getFilteredProperty,
  getPropertiesList,
  setCurrentPage as _setPropertyCurrentPage,
  setPageSize as _setPropertyPageSize,
} from "../../feature/slice/PropertyDetailsSlice";
import {
  getFilteredCompany,
  setCurrentPage as _setCompanyCurrentPage,
  setPageSize as _setCompanyPageSize,
  getCompaniesList,
} from "../../feature/slice/CompanyDetailsSlice";
import {
  getFilteredConnect,
  setCurrentPage as _setConnectCurrentPage,
  setPageSize as _setConnectPageSize,
  getConnectList,
} from "../../feature/slice/ConnectDetailsSlice";
import {
  getFilteredTransaction,
  setCurrentPage as _setTransactionCurrentPage,
  setPageSize as _setTransactionPageSize,
  getTransactionList,
} from "../../feature/slice/TransactionDetailsSlice";
import { Input, Select } from 'antd';
import "./FilterCard.css";
import dayjs from "dayjs";

const { Option } = Select;

const FilterCard = (props) => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    localitiesData,
    propertyFilters,
    transactionFilters,
    companyFilters,
    connectFilters,
    citiesData,
  } = useSelector((store) => store.filters);

  const [filterVisible, setFilterVisible] = useState(true);

  useEffect(() => {
    if (props?.selectedNav === "Property") {
      dispatch(getLocalities());
    } else if (props?.selectedNav === "Connect") {
      dispatch(getCities());
    } else if (props?.selectedNav === "Transactions") {
      dispatch(getLocalities());
    }
  }, [props?.selectedNav]);

  useEffect(() => {
    dispatch(getCities());
    if (props?.selectedNav === "Property") dispatch(getLocalities());
  }, [props?.selectedNav, dispatch]);


  const propertyFilterItems = [
    {
      key: "1",
      label: "Building Name",
      children: (
        <SearchInput
          placeholder="Please input to search by Building Name"
          style={{
            width: 250,
          }}
          name="buildingName"
          obj="Property"
          value={propertyFilters?.buildingName}
        />
      ),
    },
    {
      key: "2",
      label: "Doc No.",
      children: (
        <SearchInput
          placeholder="Please input to search by Doc No."
          style={{
            width: 250,
          }}
          name="documentNo"
          obj="Property"
          value={propertyFilters?.documentNo}
        />
      ),
    },
    {
      key: "3",
      label: "Locality",
      children: (
        <FiltersTree
          name="localities"
          data={localitiesData}
          value={propertyFilters?.localities}
          obj="Property"
        />
      ),
    },
  ];
  const transactionsFilterItems = [
    {
      key: "1",
      label: "Building Name",
      children: (
        <SearchInput
          placeholder="Please input to search by Building Name"
          style={{
            width: 250,
          }}
          name="propertyName"
          obj="Transactions"
          value={transactionFilters?.propertyName}
        />
      ),
    },
    {
      key: "2",
      label: "Doc No.",
      children: (
        <SearchInput
          placeholder="Please input to search by Doc No."
          style={{
            width: 250,
          }}
          name="documentNo"
          obj="Transactions"
          value={transactionFilters?.documentNo}
        />
      ),
    },
    {
      key: "3",
      label: "Locality",
      children: (
        <FiltersTree
          name="localities"
          data={localitiesData}
          value={transactionFilters?.localities}
          obj="Transactions"
        />
      ),
    },
  ];
  const companyFilterItems = [
    {
      key: "1",
      label: "CIN",
      children: (
        <SearchInput
          placeholder="Please input to search by CIN"
          style={{
            width: 250,
          }}
          name="cin"
          obj="Company"
          value={companyFilters?.cin}
        />
      ),
    },
    {
      key: "2",
      label: "Company Name",
      children: (
        <SearchInput
          placeholder="Please input to search by Company Name"
          style={{
            width: 250,
          }}
          name="companyName"
          obj="Company"
          value={companyFilters?.companyName}
        />
      ),
    },
    {
      key: "3",
      label: "Company Type",
      children: (
        <SearchInput
          placeholder="Please input to search by Company Type"
          style={{
            width: 250,
          }}
          name="companyType"
          obj="Company"
          value={companyFilters?.companyType}
        />
      ),
    },
    {
      key: "4",
      label: "City",
      children: (
        <FiltersTree
          name="cities"
          data={citiesData}
          value={companyFilters?.cities}
          obj="Company"
        />
      ),
    },
  ];
  const connectFilterItems = [
    {
      key: "1",
      label: "Person Name",
      children: (
        <SearchInput
          placeholder="Please input to search by Person Name"
          style={{
            width: 250,
          }}
          name="personName"
          obj="Connect"
          value={connectFilters?.personName}
        />
      ),
    },
    {
      key: "2",
      label: "Current Company",
      children: (
        <SearchInput
          placeholder="Please input to search by Current Company"
          style={{
            width: 250,
          }}
          name="currentCompany"
          obj="Connect"
          value={connectFilters?.currentCompany}
        />
      ),
    },
    {
      key: "3",
      label: "Email Address",
      children: (
        <SearchInput
          placeholder="Please input to search by Email Address"
          style={{
            width: 250,
          }}
          name="emailAddress"
          obj="Connect"
          value={connectFilters?.emailAddress}
        />
      ),
    },
    {
      key: "4",
      label: "Contact Number",
      children: (
        <SearchInput
          placeholder="Please input to search by Contact Number"
          style={{
            width: 250,
          }}
          name="contactNumber"
          obj="Connect"
          value={connectFilters?.contactNumber}
        />
      ),
    },
  ];
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Filters are empty. Please enter values to filter.",
    });
  };


  const handleFilterSearch = () => {
    if (props?.selectedNav === "Property") {
      if (
        propertyFilters?.documentNo ||
        propertyFilters?.buildingName ||
        propertyFilters?.localities?.length > 0
      ) {
        const localitiesDataForFilter = propertyFilters?.localities?.map(
          (index) => localitiesData?.[index]?.title
        );

        const body = {
          documentNumber: propertyFilters?.documentNo,
          buildingName: propertyFilters?.buildingName,
          localities: localitiesDataForFilter,
          currentPage: 1,
          pageSize: 10,
        };
        dispatch(getFilteredProperty(body));
        dispatch(_setPropertyCurrentPage(1));
        dispatch(_setPropertyPageSize(10));
        setFilterVisible(true);
      } else {
        warning();
      }
    } else if (props?.selectedNav === "Transactions") {
      if (
        transactionFilters?.propertyName ||
        transactionFilters?.documentNo ||
        transactionFilters?.localities?.length > 0
      ) {
        const localitiesDataForFilter = transactionFilters?.localities?.map(
          (index) => localitiesData?.[index]?.title
        );
        const body = {
          documentNumber: transactionFilters?.documentNo,
          buildingName: transactionFilters?.propertyName,
          localities: localitiesDataForFilter,
          currentPage: 1,
          pageSize: 10,
        };
        dispatch(getFilteredTransaction(body));
        dispatch(_setTransactionCurrentPage(1));
        dispatch(_setTransactionPageSize(10));
        setFilterVisible(true);
      } else {
        warning();
      }
    } else if (props?.selectedNav === "Company") {
      if (
        companyFilters?.cin ||
        companyFilters?.companyName ||
        companyFilters?.companyType ||
        companyFilters?.cities?.length > 0
      ) {
        const citiesDataForFilter = companyFilters?.cities?.map(
          (index) => citiesData?.[index]?.title
        );

        const body = {
          cin: companyFilters?.cin,
          companyName: companyFilters?.companyName,
          companyType: companyFilters?.companyType,
          city: citiesDataForFilter,
          currentPage: 1,
          pageSize: 10,
        };
        dispatch(getFilteredCompany(body));
        dispatch(_setCompanyCurrentPage(1));
        dispatch(_setCompanyPageSize(10));
        setFilterVisible(true); // Hide filters after search
      } else {
        warning();
      }
    } else if (props?.selectedNav === "Connect") {
      if (
        connectFilters?.personName ||
        connectFilters?.currentCompany ||
        connectFilters?.emailAddress ||
        connectFilters?.contactNumber
      ) {
        const body = {
          personName: connectFilters?.personName,
          currentCompany: connectFilters?.currentCompany,
          emailAddress: connectFilters?.emailAddress,
          contactNumber: connectFilters?.contactNumber,
          currentPage: 1,
          pageSize: 10,
        };
        dispatch(getFilteredConnect(body));
        dispatch(_setConnectCurrentPage(1));
        dispatch(_setConnectPageSize(10));
        setFilterVisible(true); // Hide filters after search
      } else {
        warning();
      }
    }
  };


  const handleResetFilters = () => {
    if (props?.selectedNav === "Property") {
      dispatch(setFilters({ name: "localities", value: [], obj: "Property" }));
      dispatch(setFilters({ value: null, name: "buildingName", obj: "Property" }));
      dispatch(setFilters({ value: null, name: "documentNo", obj: "Property" }));
    } else if (props?.selectedNav === "Transactions") {
      dispatch(setFilters({ name: "localities", value: [], obj: "Transactions" }));
      dispatch(setFilters({ value: null, name: "buildingName", obj: "Transactions" }));
      dispatch(setFilters({ value: null, name: "documentNo", obj: "Transactions" }));
    } else if (props?.selectedNav === "Company") {
      dispatch(setFilters({ name: "cin", value: null, obj: "Company" }));
      dispatch(setFilters({ value: null, name: "companyName", obj: "Company" }));
      dispatch(setFilters({ value: null, name: "companyType", obj: "Company" }));
      dispatch(setFilters({ value: [], name: "city", obj: "Company" }));
    } else if (props?.selectedNav === "Connect") {
      dispatch(setFilters({ name: "personName", value: null, obj: "Connect" }));
      dispatch(setFilters({ value: null, name: "currentCompany", obj: "Connect" }));
      dispatch(setFilters({ value: null, name: "emailAddress", obj: "Connect" }));
      dispatch(setFilters({ value: null, name: "contactNumber", obj: "Connect" }));
    }

    // Dispatch the actions to reset the current page and page size for all categories
    dispatch(getPropertiesList({ pageSize: 10, page: 1 }));
    dispatch(getTransactionList({ pageSize: 10, page: 1 }));
    dispatch(getCompaniesList({ pageSize: 10, page: 1 }));
    dispatch(getConnectList({ pageSize: 10, page: 1 }));
  };

  const filterItems = {
    Property: propertyFilterItems,
    Transactions: transactionsFilterItems,
    Company: companyFilterItems,
    Connect: connectFilterItems,
  };

  return (
    <Fragment>
      {props?.loading ? (
        <Spin spinning={props?.loading} size="large" />
      ) : (
        <div className="w-1/6 p-4">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{ display: "flex", alignItems: "center", height: "40px" }}
            >
              <div style={{ display: "flex" }}>
                <img style={{ width: "24px" }} src={FilterIcon} alt="Filter" />
                Filter
              </div>
            </button>
            <button
              onClick={props.onClose}
              style={{ right: "0.5rem", cursor: "pointer" }}
            >
              <img
                src={CloseIcon}
                alt="Close"
                style={{ width: "24px", height: "24px" }}
              />
            </button>
          </div>
          {contextHolder}
          {filterVisible && (
            <Collapse items={filterItems[props?.selectedNav]} defaultActiveKey={[1, 2, 3, 4]} />
          )}
          <div className="mt-4 text-center">
            <Space>
              <Button type="primary" onClick={handleFilterSearch}>
                Search
              </Button>
              <Button onClick={handleResetFilters}>Reset</Button>
            </Space>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default FilterCard;


const CommonFilter = ({
  filterValue,
  onFilterChange,
  onApply,
  onReset,
  filterTypeOptions = [],
  inputType = 'text',
  mode = 'default',
  placeholder = 'Enter value',
  selectOptions = [],
  filterType,
  onFilterTypeChange,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange
}) => {
  return (
    <div style={{ padding: 8 }}>
      {inputType === 'text' && (
        <Input
          placeholder={placeholder}
          value={filterValue}
          onChange={(e) => onFilterChange(e.target.value)}
          onPressEnter={onApply}
          style={{ marginBottom: 8, display: 'block' }}
        />
      )}

      {inputType === 'number' && (
        <Input
          className="no-spinner"
          placeholder={placeholder}
          type="number"
          value={filterValue}
          onChange={(e) => onFilterChange(e.target.value)}
          onPressEnter={onApply}
          style={{ marginBottom: 8, display: 'block' }}
        />
      )}

      {inputType === 'select' && (
        <Select
          mode={mode}
          showSearch
          style={{ width: 200, marginBottom: 8 }}
          placeholder={placeholder}
          value={filterValue}
          onChange={onFilterChange}
          filterOption={(input, option) =>
            option?.children.toLowerCase().startsWith(input.toLowerCase())
          }
        >
          {selectOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      )}

      {inputType === 'dateRange' && (
        <div style={{ marginBottom: 8 }}>
          <DatePicker
            format={"DD-MM-YYYY"}
            placeholder="Start Date"
            value={startDate ? dayjs(startDate) : null}
            onChange={onStartDateChange}
            style={{ marginRight: 8 }}
          />
          <DatePicker
            format={"DD-MM-YYYY"}
            placeholder="End Date"
            value={endDate ? dayjs(endDate) : null}
            onChange={onEndDateChange}
          />
        </div>
      )}

      {filterTypeOptions.length > 0 && (
        <Select
          value={filterType}
          onChange={onFilterTypeChange}
          style={{ width: 200, marginBottom: 8 }}
        >
          {filterTypeOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      )}

      <Space style={{ padding: 8 }}>
        <Button
          type="primary"
          onClick={onApply}
          size="small"
        >
          Apply
        </Button>
        <Button
          onClick={onReset}
          size="small"
        >
          Reset
        </Button>
      </Space>
    </div>
  );
};


export { CommonFilter };