import React, { useEffect } from "react";
import { Table, Typography, Pagination, Button } from "antd";
import { useNavigate, useParams } from "react-router";
// import "./Directors.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentPage,
  setPageSize,
  getDirectorsList,
  setDirectorInitialState,
  setDirectorId,
} from "../../feature/slice/DirectorDetailsSlice";
import Header from "../../components/header/Header";

const Directors = () => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading, directorDetailsData, currentPage, pageSize } = useSelector(
    (store) => store.directorDetails
  );

  const { directorsCnt } = useSelector((store) => store.companyDetails);
  const handleRowClick = (record) => {
    navigateTo(`/directorDetails/${record.directorId}`);
  };

  const columns = [
    {
      title: "Director Name",
      dataIndex: "directorName",
      width: 250,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleRowClick(record)}>
          {text}
        </div>
      ),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      width: 200,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleRowClick(record)}>
          {text}
        </div>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      width: 250,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleRowClick(record)}>
          {text}
        </div>
      ),
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      width: 250,
      render: (text, record) => (
        <div style={{ cursor: "pointer" }} onClick={() => handleRowClick(record)}>
          {text}
        </div>
      ),
    },
  ];


  useEffect(() => {
    const body = {
      pageSize: pageSize,
      page: currentPage,
      companyId: id,
    };
    dispatch(getDirectorsList(body));
  }, []);

  const handlePaginationChange = (page) => {
    dispatch(setCurrentPage(page));
    const body = {
      pageSize: pageSize,
      page: page,
      companyId: id,
    };
    dispatch(getDirectorsList(body));
  };

  const onShowSizeChange = (current, pageSize) => {
    dispatch(setPageSize(pageSize));
    dispatch(setCurrentPage(current));
    const body = {
      pageSize: pageSize,
      page: current,
      companyId: id,
    };
    dispatch(getDirectorsList(body));
  };
  return (
    <React.Fragment>
      <div className="w-100 h-16 bg-orange-400 flex align-center items-center p-4">
        <Header />
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          style={{
            display: "inline-flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "1rem",
            paddingRight: "10px",
            marginBottom: "20px",
          }}
        >
          <h1 className="m-4">Directors List</h1>
          <div className="list-options m-4">
            <Button
              className="mr-4"
              onClick={() => {
                navigateTo("/directorDetails");
                dispatch(setDirectorId(null));
                dispatch(setDirectorInitialState());
              }}
            >
              Add Director
            </Button>
            <Button
              onClick={() => {
                navigateTo("/dashboard");
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <Table
          className="m-4"
          bordered
          dataSource={directorDetailsData}
          columns={columns}
          size="small"
          loading={loading}
          rowKey="directorId"
          pagination={false}
          style={{ margin: "auto", width: "98%" }}
        />
        <br />
        {directorDetailsData?.length > 0 && (
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            showSizeChanger
            onShowSizeChange={onShowSizeChange}
            onChange={(currPage, pageSize) =>
              handlePaginationChange(currPage, pageSize)
            }
            total={directorsCnt}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default Directors;
