import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import {
  getDirectorsList as _getDirectorsList,
  getDirectorDetailsData as _getDirectorDetailsData,
  updateDirectorDetailsData as _updateDirectorDetailsData,
  saveDirectorDetailsData as _saveDirectorDetailsData,
  getAllDistinctCountryMaster as _getAllDistinctCountryMaster,
  getAllDistinctStateByCountry as _getAllDistinctStateByCountry,
  getAllDistinctDistrictByState as _getAllDistinctDistrictByState,
} from "../services/DirectorDetailsAPI";
import { get } from "lodash";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  directorDetailsData: [],
  isDirectorInfoEditable: true,
  currentPage: 1,
  pageSize: 10,
  directorDetails: {},
  directorId: null,
  directorsCnt: 0,
  countryMaster: [],
  stateMaster: [],
  districtMaster: [],
};

const directorDetailsSlice = createSliceWithThunks({
  name: "directorDetails",
  initialState,
  reducers: (create) => ({
    setDirectorInfoEditable: (state, action) => {
      state.isDirectorInfoEditable = action?.payload;
    },
    getDirectorsList: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          let data = await _getDirectorsList(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.directorDetailsData = [];
        },
        rejected: (state, action) => {
          state.loading = false;
          state.directorDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.directorDetailsData = action?.payload?.data?.data;
          state.directorsCnt = action?.payload?.data?.count;
          state.loading = false;
        },
      }
    ),
    setDirectorsInfo: (state, action) => {
      if (action?.payload?.name === "newAddress") {
        state.directorDetails = {
          ...state.directorDetails,
          addresses: state.directorDetails.addresses
            ? [...state.directorDetails.addresses, {}]
            : [{}],
        };
      } else
        state.directorDetails = {
          ...state.directorDetails,
          [action?.payload?.name]: action?.payload?.value,
        };
    },
    updateDirectorsInfo: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const directorId = thunkAPI.getState().directorDetails?.directorId;
          let data = await _updateDirectorDetailsData(_, directorId);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          // state.loading = true;
        },
        rejected: (state, action) => {
          // state.loading = false;
        },
        fulfilled: (state, action) => {
          // state.loading = false;
        },
      }
    ),
    saveDirectorsInfo: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const companyId = thunkAPI.getState().companyDetails?.companyId;
          let data = await _saveDirectorDetailsData(_, companyId);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
        },
        rejected: (state, action) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.loading = false;
          state.directorId = action?.payload?.data?.director?._id;
        },
      }
    ),
    setCurrentPage: (state, action) => {
      state.currentPage = action?.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action?.payload;
    },
    getDirectorDetailsData: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const companyId = thunkAPI.getState().companyDetails?.companyId;
          let data = await _getDirectorDetailsData(param, companyId);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.directorDetails = {};
        },
        rejected: (state, action) => {
          state.loading = false;
          state.directorDetails = {};
        },
        fulfilled: (state, action) => {
          state.directorDetails = action?.payload?.data?.directorDetails;
          state.loading = false;
        },
      }
    ),
    setDirectorId: (state, action) => {
      state.directorId = action?.payload;
    },
    setDirectorInitialState: (state, action) => {
      state.loading = false;
      state.directorDetailsData = [];
      state.isDirectorInfoEditable = true;
      state.currentPage = 1;
      state.pageSize = 10;
      state.directorDetails = {};
      state.countryMaster = [];
      state.directorId = null;
      state.stateMaster = [];
      state.districtMaster = [];
    },
    setLoading: (state, action) => {
      state.loading = action?.payload;
    },
    getAllDistinctCountryMaster: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          let data = await _getAllDistinctCountryMaster();
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.countryMaster = [];
        },
        rejected: (state, action) => {
          state.countryMaster = [];
        },
        fulfilled: (state, action) => {
          const countryMasterArray = [];
          for (let index = 0; index < action?.payload?.data?.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            countryMasterArray.push(element);
          }
          state.countryMaster = countryMasterArray;
        },
      }
    ),
    getAllDistinctStateByCountry: create.asyncThunk(
      async (country, thunkAPI) => {
        try {
          let data = await _getAllDistinctStateByCountry(country);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.stateMaster = [];
        },
        rejected: (state, action) => {
          state.stateMaster = [];
        },
        fulfilled: (state, action) => {
          const stateMasterArray = [];
          for (let index = 0; index < action?.payload?.data.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            stateMasterArray.push(element);
          }
          state.stateMaster = stateMasterArray;
        },
      }
    ),
    getAllDistinctDistrictByState: create.asyncThunk(
      async (state, thunkAPI) => {
        try {
          let data = await _getAllDistinctDistrictByState(state);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.districtMaster = [];
        },
        rejected: (state, action) => {
          state.districtMaster = [];
        },
        fulfilled: (state, action) => {
          const districtMasterArray = [];
          for (let index = 0; index < action?.payload?.data.length; index++) {
            const element = {
              label: action?.payload?.data?.[index],
              key: action?.payload?.data?.[index],
              value: action?.payload?.data?.[index],
            };
            districtMasterArray.push(element);
          }
          state.districtMaster = districtMasterArray;
        },
      }
    ),
  }),
});

export const {
  getDirectorsList,
  setDirectorInfoEditable,
  setDirectorsInfo,
  updateDirectorsInfo,
  setCurrentPage,
  setPageSize,
  getDirectorDetailsData,
  setDirectorId,
  setDirectorInitialState,
  saveDirectorsInfo,
  setLoading,
  getAllDistinctCountryMaster,
  getAllDistinctStateByCountry,
  getAllDistinctDistrictByState,
} = directorDetailsSlice.actions;
export default directorDetailsSlice.reducer;
