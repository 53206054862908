import React, { useEffect, useState } from "react";
import { Table, Typography, Pagination, Button, message, Modal, Spin } from "antd";
import { useNavigate } from "react-router";
import verifiedIcon from "../../assets/verified.svg";
import notVerifiedIcon from "../../assets/notVerified.svg";
import "./Connect.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getConnectList,
  setCurrentPage,
  setPageSize,
  setConnectId,
  getFilteredConnect,
  setConnectInitialState,
  deleteConnect,
} from "../../feature/slice/ConnectDetailsSlice";
import { setFilters } from "../../feature/slice/FiltersSlice";
import { ReloadOutlined } from "@ant-design/icons";
import { MdDeleteForever } from "react-icons/md";
import { CommonFilter } from '../../components/filterCard/FilterCard'
import dayjs from "dayjs";

const Connect = (props) => {
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { loading, connectDetailsData, currentPage, pageSize, connectsCnt } =
    useSelector((store) => store.connectDetails);
  const [filterConnect, setFilterConnect] = useState({
    personName: null,
    personNameFilter: 'contains',
    currentCompany: null,
    currentCompanyFilter: 'contains',
    emailAddress: null,
    emailAddressFilter: 'contains',
    contactNumber: null,
    contactNumberFilter: 'contains',
    startDate: null,
    endDate: null,
    designation: null,
    designationFilter: 'contains',
    currentIndustry: null,
    currentIndustryFilter: 'contains',
    experience: null,
    experienceFilter: 'equals',
    keySkills: null,
    keySkillsFilter: 'contains',
  })

  const [messageApi, contextHolder] = message.useMessage();


  const handleDelete = (connectId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this connect?",
      onOk: async () => {
        try {
          const response = await dispatch(deleteConnect(connectId)).unwrap();
          messageApi.success(response.message || "Connect deleted successfully");

          setTimeout(() => {
            dispatch(setCurrentPage(1));
            dispatch(getConnectList({ pageSize, page: 1 }));
          }, 3000);
        } catch (error) {
          messageApi.error(error.message || "Failed to delete connect");
        }
      },
    });
  };

  const handleRowClick = (record) => {
    handleResetConnectSearch();
    dispatch(setConnectId(record?._id));
    window.open(`/connectDetails/${record?._id}`, '_blank');
  };

  const applyFilter = (confirm) => {
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    dispatch(getFilteredConnect({ ...filterConnect, currentPage: 1, pageSize: 10 }));
    confirm();
  }

  const resetFilter = (columnKey, defaultFilterValue, defaultFilterType, clearFilters, confirm) => {
    const updatedFilterConnect = { ...filterConnect, [columnKey]: defaultFilterValue, [`${columnKey}Filter`]: defaultFilterType };
    setFilterConnect(updatedFilterConnect);
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    dispatch(getFilteredConnect({ ...updatedFilterConnect, currentPage: 1, pageSize: 10, }));
    clearFilters();
    confirm();
  };

  const columns = [
    {
      title: () => (
        <div className={filterConnect.personName ? 'titleHighlight' : 'titleDefault'}>
          Person Name
        </div>
      ),
      dataIndex: "personName",
      width: 200,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", columnGap: "12px" }}>
            <img
              src={record?.isVerified ? verifiedIcon : notVerifiedIcon}
              alt={record?.isVerified ? "verified" : "not verified"}
              style={{ width: "18px" }}
            />
            <Typography.Link style={{ color: "#000000" }}>
              {record?.personName}
            </Typography.Link>
          </div>
        );
      },
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          placeholder="Enter the person name here"
          filterValue={filterConnect.personName}
          filterType={filterConnect.personNameFilter}
          onFilterChange={(value) => setFilterConnect({ ...filterConnect, personName: value })}
          onFilterTypeChange={(value) => setFilterConnect({ ...filterConnect, personNameFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("personName", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: () => (
        <div className={filterConnect.emailAddress ? 'titleHighlight' : 'titleDefault'}>
          Email Address
        </div>
      ),
      dataIndex: "emailAddress",
      width: 150,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          placeholder="Enter the email id here"
          filterValue={filterConnect.emailAddress}
          filterType={filterConnect.emailAddressFilter}
          onFilterChange={(value) => setFilterConnect({ ...filterConnect, emailAddress: value })}
          onFilterTypeChange={(value) => setFilterConnect({ ...filterConnect, emailAddressFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("emailAddress", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' }
          ]}
        />
      ),
      filterSearch: true,

    },
    {
      title: () => (
        <div className={filterConnect.contactNumber ? 'titleHighlight' : 'titleDefault'}>
          Contact Number
        </div>
      ),
      dataIndex: "contactNumber",
      width: 100,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          filterValue={filterConnect.contactNumber}
          filterType={filterConnect.contactNumberFilter}
          onFilterChange={(value) => setFilterConnect({ ...filterConnect, contactNumber: value })}
          onFilterTypeChange={(value) => setFilterConnect({ ...filterConnect, contactNumberFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("contactNumber", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
          placeholder="Enter the contact number here"
        />
      ),
      filterSearch: true,
    },
    {
      title: "Linkedin",
      dataIndex: "linkedIn",
      width: 100,
    },
    {
      title: () => (
        <div className={filterConnect.currentCompany ? 'titleHighlight' : 'titleDefault'}>
          Current Company
        </div>
      ),
      dataIndex: "currentCompany",
      width: 200,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Link style={{ color: "#000000" }}>
              {record?.currentCompany}
            </Typography.Link>
          </div>
        );
      },
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          placeholder="Enter the current company here"
          filterValue={filterConnect.currentCompany}
          filterType={filterConnect.currentCompanyFilter}
          onFilterChange={(value) => setFilterConnect({ ...filterConnect, currentCompany: value })}
          onFilterTypeChange={(value) => setFilterConnect({ ...filterConnect, currentCompanyFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("currentCompany", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: () => (
        <div className={filterConnect.designation ? 'titleHighlight' : 'titleDefault'}>
          Designation
        </div>
      ),
      dataIndex: "designation",
      width: 110,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          placeholder="Enter the designation here"
          filterValue={filterConnect.designation}
          filterType={filterConnect.designationFilter}
          onFilterChange={(value) => setFilterConnect({ ...filterConnect, designation: value })}
          onFilterTypeChange={(value) => setFilterConnect({ ...filterConnect, designationFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("designation", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: () => (
        <div className={filterConnect.currentIndustry ? 'titleHighlight' : 'titleDefault'}>
          Current Industry
        </div>
      ),
      dataIndex: "currentIndustry",
      width: 100,
      render: (text) => (
        Array.isArray(text) ? text.join(', ') : text
      ),
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          placeholder="Enter the current industry name here"
          filterValue={filterConnect.currentIndustry}
          filterType={filterConnect.currentIndustryFilter}
          onFilterChange={(value) => setFilterConnect({ ...filterConnect, currentIndustry: value })}
          onFilterTypeChange={(value) => setFilterConnect({ ...filterConnect, currentIndustryFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("currentIndustry", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: () => (
        <div className={filterConnect.experience ? 'titleHighlight' : 'titleDefault'}>
          Experience
        </div>
      ),
      dataIndex: "experience",
      width: 100,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="number"
          placeholder="Search the connect experience here"
          filterValue={filterConnect.experience}
          filterType={filterConnect.experienceFilter}
          onFilterChange={(value) => setFilterConnect({ ...filterConnect, experience: value })}
          onFilterTypeChange={(value) => setFilterConnect({ ...filterConnect, experienceFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("experience", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'equals', label: 'Equal' },
            { value: 'lessthan', label: 'Less Than' },
            { value: 'greaterthan', label: 'Greater Than' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: () => (
        <div className={filterConnect.keySkills ? 'titleHighlight' : 'titleDefault'}>
          Key Skills
        </div>
      ),
      dataIndex: "keySkills",
      width: 100,
      render: (text) => (
        Array.isArray(text) ? text.join(', ') : text
      ),
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          placeholder="Search the connect key skills here"
          filterValue={filterConnect.keySkills}
          filterType={filterConnect.keySkillsFilter}
          onFilterChange={(value) => setFilterConnect({ ...filterConnect, keySkills: value })}
          onFilterTypeChange={(value) => setFilterConnect({ ...filterConnect, keySkillsFilter: value })}
          onApply={() => {
            applyFilter(confirm);
          }}
          onReset={() => {
            resetFilter("keySkills", null, "contains", clearFilters, confirm);
          }}
          filterTypeOptions={[
            { value: 'contains', label: 'Contains' },
            { value: 'startsWith', label: 'Starts with' },
            { value: 'endsWith', label: 'Ends with' },
          ]}
        />
      ),
      filterSearch: true,
    },
    {
      title: () => (
        <div className={filterConnect.startDate || filterConnect.endDate ? 'titleHighlight' : 'titleDefault'}>
          Created Date
        </div>
      ),
      dataIndex: "createdAt",
      width: 100,
      render: (createdAt) =>
        createdAt ? dayjs(createdAt).format("DD-MM-YYYY") : null,
      filterDropdown: ({ confirm, clearFilters }) => (
        <CommonFilter
          inputType="dateRange"
          startDate={filterConnect.startDate}
          endDate={filterConnect.endDate}
          onStartDateChange={(date) => setFilterConnect({ ...filterConnect, startDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onEndDateChange={(date) => setFilterConnect({ ...filterConnect, endDate: dayjs(date, "DD-MM-YYYY").add(5, "hour").add(30, "minute") })}
          onApply={() => {
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            dispatch(getFilteredConnect({ ...filterConnect, currentPage: 1, pageSize: 10, }));
            confirm();
          }}
          onReset={() => {
            const updatedFilterConnect = { ...filterConnect, startDate: null, endDate: null }
            setFilterConnect(updatedFilterConnect);
            dispatch(setCurrentPage(1));
            dispatch(setPageSize(10));
            dispatch(getFilteredConnect({ ...updatedFilterConnect, currentPage: 1, pageSize: 10, }));
            clearFilters();
            confirm();
          }}
        />
      ),
    },

    {
      title: "Completion Ratio",
      dataIndex: "percentage",
      width: 77,
      render: (_, record) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {record?.percentage}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "delete",
      width: 50,
      editable: true,
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(record._id);
          }}
        >
          <MdDeleteForever size={20} className="text-secondary" />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(setConnectInitialState());
    dispatch(setCurrentPage(1));
    dispatch(setPageSize(10));
    if (filterConnect?.personName || filterConnect?.currentCompany || filterConnect.emailAddress || filterConnect.contactNumber ||
      filterConnect?.startDate || filterConnect?.endDate || filterConnect?.designation || filterConnect?.currentIndustry ||
      filterConnect?.experience || filterConnect?.keySkills) {
      const body = {
        personName: filterConnect?.personName,
        personNameFilter: filterConnect?.personNameFilter,
        currentCompany: filterConnect?.currentCompany,
        currentCompanyFilter: filterConnect?.currentCompanyFilter,
        emailAddress: filterConnect?.emailAddress,
        contactNumber: filterConnect?.contactNumber,
        contactNumberFilter: filterConnect?.contactNumberFilter,
        startDate: filterConnect?.startDate,
        endDate: filterConnect?.endDate,
        designation: filterConnect?.designation,
        designationFilter: filterConnect?.designationFilter,
        currentIndustry: filterConnect?.currentIndustry,
        currentIndustryFilter: filterConnect?.currentIndustryFilter,
        experience: filterConnect?.experience,
        experienceFilter: filterConnect?.experienceFilter,
        keySkills: filterConnect?.keySkills,
        keySkillsFilter: filterConnect?.keySkillsFilter,
        currentPage: 1,
        pageSize: 10,
      };
      dispatch(getFilteredConnect(body));
    } else {
      const body = {
        pageSize: 10,
        page: 1,
      };
      dispatch(getConnectList(body));
    }
  }, []);

  const handleResetConnectSearch = () => {
    const personNameBody = {
      value: null,
      name: "personName",
    };
    dispatch(setFilters(personNameBody));

    const currentCompanyBody = {
      value: null,
      name: "currentCompany",
    };
    dispatch(setFilters(currentCompanyBody));

    const emailAddressBody = {
      value: null,
      name: "emailAddress",
    };
    dispatch(setFilters(emailAddressBody));

    const contactNumberBody = {
      value: null,
      name: "contactNumber",
    };
    dispatch(setFilters(contactNumberBody));
  };

  const handlePaginationChange = (page, size) => {
    dispatch(setCurrentPage(page));
    if (size === pageSize) {
      if (filterConnect?.personName || filterConnect?.currentCompany || filterConnect.emailAddress || filterConnect.contactNumber ||
        filterConnect?.startDate || filterConnect?.endDate || filterConnect?.designation || filterConnect?.currentIndustry ||
        filterConnect?.experience || filterConnect?.keySkills) {
        const body = {
          personName: filterConnect?.personName,
          personNameFilter: filterConnect?.personNameFilter,
          currentCompany: filterConnect?.currentCompany,
          currentCompanyFilter: filterConnect?.currentCompanyFilter,
          emailAddress: filterConnect?.emailAddress,
          contactNumber: filterConnect?.contactNumber,
          contactNumberFilter: filterConnect?.contactNumberFilter,
          startDate: filterConnect?.startDate,
          endDate: filterConnect?.endDate,
          designation: filterConnect?.designation,
          designationFilter: filterConnect?.designationFilter,
          currentIndustry: filterConnect?.currentIndustry,
          currentIndustryFilter: filterConnect?.currentIndustryFilter,
          experience: filterConnect?.experience,
          experienceFilter: filterConnect?.experienceFilter,
          keySkills: filterConnect?.keySkills,
          keySkillsFilter: filterConnect?.keySkillsFilter,
          currentPage: page,
          pageSize: pageSize,
        };
        dispatch(getFilteredConnect(body));
      } else {
        const body = {
          pageSize: pageSize,
          page,
        };
        dispatch(getConnectList(body));
      }
    } else dispatch(setCurrentPage(1));
  };

  const onShowSizeChange = (current, size) => {
    dispatch(setPageSize(size));
    dispatch(setCurrentPage(1));
    if (filterConnect?.personName || filterConnect?.currentCompany || filterConnect.emailAddress || filterConnect.contactNumber ||
      filterConnect?.startDate || filterConnect?.endDate || filterConnect?.designation || filterConnect?.currentIndustry ||
      filterConnect?.experience || filterConnect?.keySkills) {
      const body = {
        personName: filterConnect?.personName,
        personNameFilter: filterConnect?.personNameFilter,
        currentCompany: filterConnect?.currentCompany,
        currentCompanyFilter: filterConnect?.currentCompanyFilter,
        emailAddress: filterConnect?.emailAddress,
        contactNumber: filterConnect?.contactNumber,
        contactNumberFilter: filterConnect?.contactNumberFilter,
        startDate: filterConnect?.startDate,
        endDate: filterConnect?.endDate,
        designation: filterConnect?.designation,
        designationFilter: filterConnect?.designationFilter,
        currentIndustry: filterConnect?.currentIndustry,
        currentIndustryFilter: filterConnect?.currentIndustryFilter,
        experience: filterConnect?.experience,
        experienceFilter: filterConnect?.experienceFilter,
        keySkills: filterConnect?.keySkills,
        keySkillsFilter: filterConnect?.keySkillsFilter,
        currentPage: 1,
        pageSize: size,
      };
      dispatch(getFilteredConnect(body));
    } else {
      const body = {
        pageSize: size,
        page: 1,
      };
      dispatch(getConnectList(body));
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          {contextHolder}
          <div className="table-list-portion">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "20px",
              }}
            >
              <div className="m-4 align-center property">
                <h1>Connect List</h1>
              </div>
              <div className="list-options m-4">
                <Button
                  className="mr-4"
                  onClick={() => {
                    handleResetConnectSearch();
                    navigateTo(`/connectDetails`);
                    dispatch(setConnectId(null));
                    dispatch(setConnectInitialState());
                  }}
                >
                  Add Connect
                </Button>
                <ReloadOutlined
                  style={{ cursor: "pointer" }}
                  title="Reload Property List"
                  onClick={() => {
                    const body = {
                      pageSize: 10,
                      page: 1,
                    };
                    setFilterConnect({
                      personName: null,
                      personNameFilter: 'contains',
                      currentCompany: null,
                      currentCompanyFilter: 'contains',
                      emailAddress: null,
                      emailAddressFilter: 'contains',
                      contactNumber: null,
                      contactNumberFilter: 'contains',
                      startDate: null,
                      endDate: null,
                      designation: null,
                      designationFilter: 'contains',
                      currentIndustry: null,
                      currentIndustryFilter: 'contains',
                      experience: null,
                      experienceFilter: 'equals',
                      keySkills: null,
                      keySkillsFilter: 'contains',
                    })
                    dispatch(getConnectList(body));
                    dispatch(setPageSize(10));
                    dispatch(setCurrentPage(1));
                  }}
                />
              </div>
            </div>
            <Table
              className="w-100 m-4 mt-0"
              bordered
              dataSource={connectDetailsData}
              tableLayout="fixed"
              scroll={{ x: 300 }}
              columns={columns}
              size="small"
              loading={loading}
              pagination={false}
              rowKey={"_id"}
              onRow={(record) => {
                return {
                  onClick: () => handleRowClick(record),
                  style: { cursor: "pointer" },
                };
              }}
            />
            {connectDetailsData?.length > 0 && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={(currPage, pageSize) =>
                  handlePaginationChange(currPage, pageSize)
                }
                total={connectsCnt}
              />
            )}
          </div>
        </>
      )}
    </React.Fragment>
  );
};
export default Connect;
