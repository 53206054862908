import React, { useEffect, useState } from "react";
import { Table, Typography, Pagination, Spin, DatePicker, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setPageSize, fetchReportList } from "../../feature/slice/ReportDetailsSlice";
import dayjs from 'dayjs';
import ReportDetailsCard from "../reportDetailsCard/ReportDetailsCard";
import { useNavigate } from "react-router-dom";

const Report = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, reportDetailsData, currentPage, pageSize, reportsCnt } = useSelector((store) => store.reportDetails);

  const [selectedData, setSelectedData] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [category, setCategory] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);

  const dateFormat = 'DD/MM/YYYY';
  const { RangePicker } = DatePicker;

  const [startDate, endDate] = dateRange || [];
  const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : '';
  const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DDTHH') : '';
  const handleClick = (record, category, startDate, endDate) => {
    setSelectedData(record);
    setSelectedUserId(record.userId);
    setCategory(category);

    navigate(`/report-details/${record.userId}/${record.userName}/${category}`, {
      state: { start: formattedStartDate, end: formattedEndDate },
    });
  };

  useEffect(() => {
    dispatch(setCurrentPage(1));
    dispatch(fetchReportList({ pageSize, page: currentPage }));
  }, [dispatch, currentPage, pageSize]);

  const columns = [
    {
      title: "Username",
      dataIndex: "userName",
      render: (text) => (
        <div>
          <Typography.Text>{text}</Typography.Text>
        </div>
      ),
    },
    {
      title: "Property",
      dataIndex: "collections",
      render: (collections, record) => (
        <div onClick={() => handleClick(record, "propertyInfo")} style={{ cursor: 'pointer' }}>
          <Typography.Text>{collections?.propertyInfo || '0'}</Typography.Text>
        </div>
      ),
    },
    {
      title: "Transaction",
      dataIndex: "collections",
      render: (collections, record) => (
        <div onClick={() => handleClick(record, "transactionInfo")} style={{ cursor: 'pointer' }}>
          <Typography.Text>{collections?.transactionInfo || '0'}</Typography.Text>
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "collections",
      render: (collections, record) => (
        <div onClick={() => handleClick(record, "mcaCompanyInfo")} style={{ cursor: 'pointer' }}>
          <Typography.Text>{collections?.mcaCompanyInfo || '0'}</Typography.Text>
        </div>
      ),
    },
    {
      title: "Connect",
      dataIndex: "collections",
      render: (collections, record) => (
        <div onClick={() => handleClick(record, "personInfo")} style={{ cursor: 'pointer' }}>
          <Typography.Text>{collections?.personInfo || '0'}</Typography.Text>
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "collections",
      render: (collections, record) => (
        <div onClick={() => handleClick(record, "addressInfo")} style={{ cursor: 'pointer' }}>
          <Typography.Text>{collections?.addressInfo || '0'}</Typography.Text>
        </div>
      ),
    },
    {
      title: "Total",
      dataIndex: "collections",
      render: (collections) => {
        const propertyCount = collections?.propertyInfo || 0;
        const transactionCount = collections?.transactionInfo || 0;
        const companyCount = collections?.mcaCompanyInfo || 0;
        const connectCount = collections?.personInfo || 0;
        const addressCount = collections?.addressInfo || 0;
  
        const totalCount = propertyCount + transactionCount + companyCount + connectCount + addressCount;
  
        return (
          <div>
            <Typography.Text>{totalCount}</Typography.Text>
          </div>
        );
      },
    },
  ];


  const handleSearch = () => {
    setSelectedUserId(null);
    setCategory(null);
    const [startDate, endDate] = dateRange || [];

    const formattedStartDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : '';
    const formattedEndDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : '';

    dispatch(fetchReportList({ pageSize, page: currentPage, start: formattedStartDate, end: formattedEndDate }));
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  const handlePaginationChange = (page, size) => {
    dispatch(setCurrentPage(page));
    dispatch(setPageSize(size));
    dispatch(fetchReportList({ pageSize: size, page }));
  };


  const onShowSizeChange = (current, size) => {
    dispatch(setPageSize(size));
    dispatch(setCurrentPage(1));
    dispatch(fetchReportList({ pageSize: size, page: 1 }));
  };

  return (
    <React.Fragment>
      {loading ? (
        <Spin spinning={loading} size="large" fullscreen />
      ) : (
        <>
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between", margin: "20px" }}>
              <h1>Report List</h1>
              <div>
                <RangePicker
                  onChange={handleDateChange}
                  format={dateFormat}
                  value={dateRange}
                />
                <Button type="primary" className="ml-4" onClick={handleSearch}>Search</Button>
              </div>
            </div>
            {selectedData ? (
              <ReportDetailsCard data={selectedData} userId={selectedUserId} category={category} />
            ) : (
              <Table
                className="m-4"
                bordered
                dataSource={reportDetailsData.data}
                columns={columns}
                pagination={false}
                rowKey={"userId"}
              />
            )}
            {reportsCnt > 0 && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={handlePaginationChange}
                total={reportsCnt}
              />
            )}
          </div>
        </>
      )}
    </React.Fragment>
  );
};

export default Report;