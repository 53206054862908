import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import { getDashboardCounts as _getDashboardCounts } from "../services/DashboardDetailsAPI";
import { get } from "lodash";
const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  reportDetailsData: [],
  currentPage: 1,
  pageSize: 10,
  reportDetails: {},
  reportId: null,
  reportsCnt: 0,
  dashboardCounts: {
    totalPropertyCount: 0,
    totalTransactionCount: 0,
    totalCompanyCount: 0,
    totalConnectCount: 0,
  },
  dashboardFilter: null,
  filters: {
    state: null,
    city: null,
    locality: null,
  },
};

const dashboardSlice = createSliceWithThunks({
  name: "dashboardsDetails",
  initialState,
  reducers: (create) => ({
    fetchDashboardCounts: create.asyncThunk(
      async (_, thunkAPI) => {
        try {
          const data = await _getDashboardCounts();
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.error = null;
        },
        rejected: (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },
        fulfilled: (state, action) => {
          state.dashboardCounts = action.payload || {};
          state.loading = false;
        },
      }
    ),
    setDashboardFilter: (state, action) => {
      state.dashboardFilter = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  }),
});

export const { fetchDashboardCounts, setError, setDashboardFilter } =
  dashboardSlice.actions;

export default dashboardSlice.reducer;
