export const FormatTransactionPartiesInfo = (data, type) => {
  const transactionPartiesInfo = [];
  for (let index = 0; index < data.length; index++) {
    let element = {};
    if (type === "buyerInfo") {
      let buyerType = "";
      if (
        data?.[index]?.companyName ||
        data?.[index]?.companyEmail ||
        data?.[index]?.companyNumber ||
        data?.[index]?.cin ||
        data?.[index]?.companyId ||
        data?.[index]?.representative?.personName ||
        data?.[index]?.representative?.role ||
        data?.[index]?.representative?.aadharNumber ||
        data?.[index]?.representative?.emailId ||
        data?.[index]?.representative?.contactNumber
      ) {
        buyerType = "Company";
      } else buyerType = "Individual";
      element = {
        companyName: data?.[index]?.companyName,
        companyEmail: data?.[index]?.companyEmail,
        companyContact: data?.[index]?.companyNumber,
        cin: data?.[index]?.cin,
        companyId: data?.[index]?.companyId,
        representativeName: data?.[index]?.representative?.personName,
        representativeRole: data?.[index]?.representative?.role,
        representativeAadhar: data?.[index]?.representative?.aadharNumber,
        representativePan: data?.[index]?.representative?.panNumber,
        representativeEmail: data?.[index]?.representative?.emailId,
        representativeContact: data?.[index]?.representative?.contactNumber,
        name: data?.[index]?.personName,
        pan: data?.[index]?.panNumber,
        aadhar: data?.[index]?.aadharNumber,
        contact: data?.[index]?.contactNumber,
        email: data?.[index]?.emailId,
        buyerType,
      };
    } else {
      let sellerType = "";
      if (
        data?.[index]?.companyName ||
        data?.[index]?.companyEmail ||
        data?.[index]?.companyNumber ||
        data?.[index]?.cin ||
        data?.[index]?.companyId ||
        data?.[index]?.representative?.personName ||
        data?.[index]?.representative?.role ||
        data?.[index]?.representative?.aadharNumber ||
        data?.[index]?.representative?.emailId ||
        data?.[index]?.representative?.contactNumber
      ) {
        sellerType = "Company";
      } else sellerType = "Individual";
      element = {
        companyName: data?.[index]?.companyName,
        companyEmail: data?.[index]?.companyEmail,
        companyContact: data?.[index]?.companyNumber,
        cin: data?.[index]?.cin,
        companyId: data?.[index]?.companyId,
        representativeName: data?.[index]?.representative?.personName,
        representativeRole: data?.[index]?.representative?.role,
        representativeAadhar: data?.[index]?.representative?.aadharNumber,
        representativePan: data?.[index]?.representative?.panNumber,
        representativeEmail: data?.[index]?.representative?.emailId,
        representativeContact: data?.[index]?.representative?.contactNumber,
        name: data?.[index]?.personName,
        pan: data?.[index]?.panNumber,
        aadhar: data?.[index]?.aadharNumber,
        contact: data?.[index]?.contactNumber,
        email: data?.[index]?.emailId,
        sellerType,
      };
    }
    transactionPartiesInfo.push(element);
  }
  return transactionPartiesInfo;
};
