import { Input } from "antd";
import { setFilters } from "../../feature/slice/FiltersSlice";
import { useDispatch } from "react-redux";

const SearchInput = (props) => {
  const dispatch = useDispatch();
  const handleValueChange = (e, name, obj) => {
    const body = {
      value: e.target.value,
      name,
      obj,
    };
    dispatch(setFilters(body));
  };

  return (
    <Input
      placeholder={props?.placeholder}
      name={props?.name}
      value={props?.value}
      onChange={(e) => handleValueChange(e, props?.name, props?.obj)}
    />
  );
};

export default SearchInput;
