import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import {
  getReportList as _getReportList,
  getReportDetails as _getReportDetails,
} from "../services/ReportDetailsAPI";
import { get, set } from "lodash";

const createSliceWithThunks = buildCreateSlice({
  creators: {
    asyncThunk: asyncThunkCreator,
  },
});

const initialState = {
  loading: false,
  reportDetailsData: [],
  currentPage: 1,
  pageSize: 10,
  reportDetails: {},
  reportId: null,
  reportsCnt: 0,
};

const reportDetailsSlice = createSliceWithThunks({
  name: "reportDetails",
  initialState,
  reducers: (create) => ({
    fetchReportList: create.asyncThunk(
      async (param, thunkAPI) => {
        try {
          const data = await _getReportList(param);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          const name = get(err, "name", "Error!");
          const statusCode = get(err, "metadata.statusCode", "");
          return thunkAPI.rejectWithValue({ message, name, statusCode });
        }
      },
      {
        pending: (state) => {
          state.loading = true;
          state.reportDetailsData = [];
        },
        rejected: (state) => {
          state.loading = false;
          state.reportDetailsData = [];
        },
        fulfilled: (state, action) => {
          state.reportDetailsData = action.payload?.data || [];
          state.reportsCnt = action.payload?.data?.count || 0;
          state.loading = false;
        },
      }
    ),
    fetchReportDetails: create.asyncThunk(
      async (params, thunkAPI) => {
        try {
          const data = await _getReportDetails(params);
          return data;
        } catch (err) {
          const message = get(err, "message", "Something Went Wrong!");
          return thunkAPI.rejectWithValue({ message });
        }
      },
      {
        pending: (state) => {
          state.loading = false;
        },
        rejected: (state) => {
          state.loading = false;
        },
        fulfilled: (state, action) => {
          state.reportDetails = action.payload?.data || {};
          state.reportsCnt = action.payload?.total || 0;
          state.loading = false;
        },
      }
    ),
    setReportDetails: (state, action) => {
      state.reportDetails = action?.payload;
    },
    setReportInfoEditable: (state, action) => {
      state.isReportInfoEditable = action.payload;
    },
    setReportInfo: (state, action) => {
      state.reportDetails = {
        ...state.reportDetails,
        [action.payload.name]: action.payload.value,
      };
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  }),
});

export const {
  fetchReportList,
  fetchReportDetails,
  setReportInfoEditable,
  setReportDetails,
  setReportInfo,
  setCurrentPage,
  setPageSize,
} = reportDetailsSlice.actions;

export default reportDetailsSlice.reducer;
